import React, { Component, Fragment } from "react";
import { withTranslation } from "react-i18next";
import ModalVideo from "react-modal-video";
import ScrollToTop from "react-scroll-up";
import Slider from "react-slick";
import HeaderFive from "../component/header/HeaderFive";
import FooterTwo from "../component/footer/FooterTwo";
import CallAction from "../elements/callaction/CallAction";
import { Link } from "react-router-dom";

import Helmet from "../component/common/Helmet";
import { FiChevronUp, FiCheck } from "react-icons/fi";
import BlogPost from "../elements/BlogPost";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class Pocetna extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
      isSubMenuOpen: false
    };
    this.openModal = this.openModal.bind(this);
  }

  openModal() {
    this.setState({ isOpen: true });
  }

  handleSubMenuToggle = () => {
    this.setState(prevState => ({
      isSubMenuOpen: !prevState.isSubMenuOpen
    }));
  };

  render() {
    const { t } = this.props;
    var namesItemOne = ["test1", "test2", "test3", "test4"];
    var namesItemTwo = ["test5", "test6", "test7"];
    var savjetovanjeItems = [
      t("pocetna.description4.item1"),
      t("pocetna.description4.item2"),
      t("pocetna.description4.item3")
    ];

    const SlideList = [
      {
        textPosition: "text-right",
        bgImage: "bg_image--34",
        category: "",
        title: t("pocetna.title1"),
        description: t("pocetna.description1"),
        buttonText: t("pocetna.buttonPonuda"),
        buttonLink: "/ponuda"
      },
      {
        textPosition: "text-left",
        bgImage: "bg_image--34",
        category: "",
        title: t("pocetna.title2"),
        description: t("pocetna.description2"),
        buttonText: t("pocetna.buttonPonuda"),
        buttonLink: "/ponuda"
      },
      {
        textPosition: "text-right",
        bgImage: "bg_image--34",
        category: "",
        title: t("pocetna.title3"),
        description: t("pocetna.description3"),
        buttonText: t("pocetna.buttonPonuda"),
        buttonLink: "/ponuda"
      },
      {
        textPosition: "text-left",
        bgImage: "bg_image--34",
        category: "",
        title: t("pocetna.title4"),
        description: (
          <ul className="list-style--1">
            {savjetovanjeItems.map((name, index) => {
              return (
                <li key={index}>
                  <FiCheck /> {name}
                </li>
              );
            })}
          </ul>
        ),
        buttonText: t("pocetna.buttonPonuda"),
        buttonLink: "/ponuda"
      }
    ];

    const sliderSettings = {
      arrows: false,
      dots: false,
      pauseOnHover: false,
      infinite: true,
      speed: 5000,
      autoplay: true,
      fade: true,
      variableWidth: false,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    const textColor = "#fff";
    console.log(this.state.isSubMenuOpen);
    return (
      <Fragment>
        <Helmet pageTitle="Revizija, računovodstvo, knjigovodstvo" />

        {/* Start Header Area  */}
        <HeaderFive
          headerPosition="header--static logoresize"
          logo="all-dark"
          color="color-black"
        />
        {/* End Header Area  */}

        {/* Start Slider Area   */}
        <div className="slider-wrapper">
          <div className="slider-activation">
            <Slider className="rn-slick-dot dot-light" {...sliderSettings}>
              {SlideList.map((value, index) => (
                <div
                  className={`slide slide-style-2 slider-box-content without-overlay d-flex align-items-center justify-content-center bg_image ${value.bgImage}`}
                  key={index}
                >
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className={`inner ${value.textPosition}`}>
                          {value.category ? <span>{value.category}</span> : ""}
                          {value.title ? (
                            <h1 className="title fontMontserrat">
                              {value.title}
                            </h1>
                          ) : (
                            ""
                          )}
                          {value.description ? (
                            <p className="description fontMontserrat">
                              {value.description}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
            <div
              className={`slide-btn ${this.state.isSubMenuOpen ? "open" : ""}`}
              style={{
                position: "absolute",
                bottom: "20px",
                left: "50%",
                transform: "translateX(-50%)",
                textAlign: "center",
                transition: "0.5s"
              }}
              onMouseEnter={this.handleSubMenuToggle}
              onMouseLeave={this.handleSubMenuToggle}
            >
              <ul
                className={`mainmenu ${this.state.isSubMenuOpen ? "open" : ""}`}
              >
                <li
                  className="has-droupdown"
                  style={{
                    alignSelf: "center",
                    padding: "20px",
                    cursor: "pointer"
                  }}
                >
                  <Link
                    className={`fontMontserrat ${
                      this.state.isSubMenuOpen ? "open" : ""
                    }`}
                    style={{
                      padding: "7px",
                      alignSelf: "center",
                      fontSize: "15px"
                    }}
                  >
                    {t("header.ponudaButton")}
                  </Link>
                  <ul
                    className={`submenu ${
                      this.state.isSubMenuOpen ? "open" : ""
                    }`}
                  >
                    <li>
                      <Link className="fontMontserrat" to="/ponuda">
                        {t("header.racunovodstvo")}
                      </Link>
                    </li>{" "}
                    <li>
                      <Link className="fontMontserrat" to="/kontakt">
                        {t("header.audit")}
                      </Link>
                    </li>{" "}
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* End Slider Area   */}

        {/* Start Featured Service Area  */}
        <div className="rn-featured-service-area bg_color--1 fingerprintPattern">
          <div className="container pt--20">
            <div className="row">
              {/* Start Single Service  */}
              <BlogPost textColor={textColor} />
            </div>
          </div>
        </div>
        {/* End Featured Service Area  */}

        {/* Start About Area  */}
        {/* <div className="rn-about-area ptb--120 bg_color--5">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-6 order-2 order-lg-1">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h2 className="title fontMontserrat">SINTEL</h2>
                    <p className="description fontMontserrat">
                      opis i video sekcija
                    </p>
                  </div>
                  <div className="mt--30">
                    <ul className="list-style--1">
                      {namesItemOne.map((name, index) => {
                        return (
                          <li className="fontMontserrat" key={index}>
                            <FiCheck /> {name}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  <div className="mt--30">
                    <ul className="list-style--1">
                      {namesItemTwo.map((name, index) => {
                        return (
                          <li className="fontMontserrat" key={index}>
                            <FiCheck /> {name}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 order-1 order-lg-2">
                <div className="thumbnail position-relative">
                  <img
                    className="w-100"
                    src="/assets/images/about/about-3.png"
                    alt="About Images"
                  />
                  {/* <ModalVideo
                    channel="youtube"
                    isOpen={this.state.isOpen}
                    videoId="ZOoVOfieAF8"
                    onClose={() => this.setState({ isOpen: false })}
                  />
                  <button
                    className="video-popup position-top-center theme-color"
                    onClick={this.openModal}
                  >
                    <span className="play-icon"></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* End About Area  */}

        {/* Start call To Action  */}
        <CallAction />
        {/* End call To Action  */}

        {/* Start Footer Style  */}
        <FooterTwo />
        {/* End Footer Style  */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}
      </Fragment>
    );
  }
}
export default withTranslation()(Pocetna);
