import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Footer from "../component/footer/Footer";
import HeaderFive from "../component/header/HeaderFive";
import { withTranslation } from "react-i18next";

class Savjetovanje extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <React.Fragment>
        {/* Start Pagehelmet  */}
        <PageHelmet pageTitle="Savjetovanje" />
        {/* End Pagehelmet  */}

        <HeaderFive
          headerPosition="header--static logoresize"
          logo="all-dark"
          color="color-black"
        />

        {/* Start Page Wrapper */}
        <div className="rn-service-details ptb--120 bg_color--5 fingerprintPattern">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="service-details-inner">
                  <div className="inner">
                    {/* Start Single Area */}
                    <div className="row sercice-details-content align-items-center">
                      <div className="col-lg-6 col-12 order-2 order-lg-1">
                        <div className="details mt_md--30 mt_sm--30">
                          <h4 className="title">
                            {" "}
                            {this.props.t("consulting.title")}
                          </h4>
                          <ul className="liststyle">
                            <li> {this.props.t("consulting.listItem1")}</li>
                            <li>{this.props.t("consulting.listItem2")}</li>
                            <li>{this.props.t("consulting.listItem3")}</li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-lg-6 col-12 order-1 order-lg-2">
                        <div className="thumb position-relative pt--20">
                          <img
                            className="w-100"
                            src="/assets/images/service/slider1-plavi.jpeg"
                            alt="Poslovno savjetovanje - Revizija Sintel"
                          />
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Page Wrapper */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default withTranslation()(Savjetovanje);
