import React, { useState, useRef } from "react";
import swal from "sweetalert2";
import ReCAPTCHA from "react-google-recaptcha";
import { withTranslation, useTranslation } from "react-i18next";

const ContactForm = () => {
  const elasticEmailAPIkey = process.env.REACT_APP_ELASTIC_EMAIL_API_KEY;
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const recaptchaRef = useRef();
  const { t } = useTranslation();

  const handleRecaptchaChange = () => {
    setIsVerified(true);
  };

  const sendEmail = async formData => {
    try {
      const form = new FormData();
      form.append("apikey", elasticEmailAPIkey);
      form.append("subject", `${"Kontakt - upit"}`);
      form.append("to", "sintel@sintel.hr");
      form.append("from", "sintel@sintel.hr");
      form.append(
        "bodyHtml",
        `
        <div style="font-family: Montserrat, sans-serif; color: #1aa6ec;">
          <h2 style="color: #FF0000;">${"Kontakt - upit"}</h2>
          <p><strong style=color: #1aa6ec;">Ime/Firma:</strong> ${
            formData.name
          }</p>
          <p><strong style=color: #1aa6ec;">Email:</strong> ${
            formData.email
          }</p>
          <p><strong style=color: #1aa6ec;">Telefon:</strong> ${
            formData.phone
          }</p>
          <p><strong style=color: #1aa6ec;">Poruka:</strong> ${
            formData.message
          }</p>
        </div>
      `
      );

      const response = await fetch(
        "https://api.elasticemail.com/v2/email/send",
        {
          method: "POST",
          body: form
        }
      );

      if (response.ok) {
        console.log("Email sent successfully");
        // Optionally, perform any success actions here
      } else {
        throw new Error("Email sending failed");
      }
    } catch (error) {
      console.error("Error sending email:", error);
      // Optionally, handle the error and perform any error actions here
    }
  };

  const handleSubmit = async event => {
    event.preventDefault(); // Prevent the default form submission behavior
    if (!isVerified) {
      // reCAPTCHA verification failed
      return;
    }
    setIsSubmitting(true);

    const formData = { name, email, phone, message }; // Collect form data

    // Call the sendEmail function
    await sendEmail(formData);

    // Clear the form fields after successful submission
    setName("");
    setEmail("");
    setPhone("");
    setMessage("");

    // Display success message
    swal.fire({
      title: t("contactForm.thankyou"),
      text: t("contactForm.messageSent"),
      icon: "success",
      timer: 4000,
      timerProgressBar: true,
      confirmButtonColor: "#0085c0"
    });
    setIsSubmitting(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="rn-form-group">
        <input
          className="fontMontserrat"
          type="text"
          value={name}
          onChange={e => setName(e.target.value)}
          placeholder={t("contactForm.name")}
          required
          style={{ backgroundColor: "#fff", borderColor: "#1a9adb" }}
        />
      </div>

      <div className="rn-form-group">
        <input
          value={email}
          onChange={e => setEmail(e.target.value)}
          className="fontMontserrat"
          type="email"
          name="email"
          placeholder={t("contactForm.email")}
          required
          style={{ backgroundColor: "#fff", borderColor: "#1a9adb" }}
        />
      </div>

      <div className="rn-form-group">
        <input
          value={phone}
          onChange={e => setPhone(e.target.value)}
          className="fontMontserrat"
          type="text"
          name="phone"
          placeholder={t("contactForm.phone")}
          required
          style={{ backgroundColor: "#fff", borderColor: "#1a9adb" }}
        />
      </div>

      <div className="rn-form-group">
        <textarea
          value={message}
          onChange={e => setMessage(e.target.value)}
          className="fontMontserrat"
          name="message"
          placeholder={t("contactForm.message")}
          required
          style={{ backgroundColor: "#fff", borderColor: "#1a9adb" }}
        ></textarea>
      </div>
      <ReCAPTCHA
        ref={recaptchaRef}
        sitekey="6Ldev18mAAAAAEm7W12m0gdnVhOOi-6-laQYezI2"
        onChange={handleRecaptchaChange}
        className="pb--20"
      />
      <div className="rn-form-group">
        <button
          className={
            !email || !name || !email || !message || !isVerified || isSubmitting
              ? "disabledButton btn-solidDisabled fontMontserrat"
              : "rn-button-style--2 btn-solid"
          }
          type="submit"
          value="submit"
          name="submit"
          id="mc-embedded-subscribe"
          disabled={
            !name || !email || !phone || !message || !isVerified || isSubmitting
          }
        >
          {isSubmitting ? t("contactForm.sending") : t("contactForm.send")}
        </button>
      </div>
    </form>
  );
};

export default withTranslation()(ContactForm);
