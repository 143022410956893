import React from "react";
import { Link } from "react-router-dom";
import { withTranslation, useTranslation } from "react-i18next";

const CallAction = () => {
  const { t } = useTranslation(); // Move useTranslation hook inside the component

  return (
    <div className="call-to-action-wrapper call-to-action text-white-wrapper  ptb--120">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="inner text-center">
              <h2 className="fontMontserrat">SINTEL</h2>
              <h3 className="fontMontserrat">{t("footer.future")}</h3>
              <Link className="rn-button-style--2 fontMontserrat" to="/kontakt">
                <span>{t("footer.contact")}</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default withTranslation()(CallAction);
