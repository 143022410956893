import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Karijera from "../elements/Karijera";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import HeaderFive from "../component/header/HeaderFive";
import Footer from "../component/footer/Footer";
import OtvorenaContact from "./contact/OtvorenaContact";
import TextLoop from "react-text-loop";
import ShareButton from "../elements/ShareButton";
import { withTranslation } from "react-i18next";

class KarijeraOverview extends Component {
  render() {
    const { t } = this.props;

    return (
      <React.Fragment>
        <PageHelmet pageTitle="Karijera" />

        <HeaderFive
          headerPosition="header--static logoresize"
          logo="all-dark"
          color="color-black"
        />

        {/* Start Blog Area */}
        <div className="rn-blog-area ptb--120 bg_color--1">
          <div className="container">
            <p className="titleCenter">
              <p className="titleCenter">Postanite dio </p>
              <br></br>
              <TextLoop interval={2500}>
                <span>
                  {" "}
                  <span className="sintelTitle">SINTEL</span> tima!
                </span>
                <span>budućnosti!</span>
              </TextLoop>{" "}
            </p>
            <Karijera />
            <div className="row mt--20">
              <div className="col-lg-12 pb--50">
                <p className="fontMontserrat justifyText">
                  Dragi potencijalni kandidati! <p></p>
                  <br /> Ako smatrate da vaše vještine, iskustvo i motivacija
                  mogu pridonijeti našem timu, pozivamo vas da pošaljete
                  otvorenu zamolbu. Otvorena zamolba predstavlja odličan način
                  da nam pokažete svoj interes za rad u našem timu, čak i ako
                  trenutno nemamo otvorene pozicije koje odgovoraju vašim
                  kvalifikacijama.<p></p> <br /> Otvorena molba omogućuje da vas
                  imamo na umu kada se otvori odgovarajuća pozicija ili kada se
                  pojavi potreba za dodatnim članovima u našem timu. Molimo vas
                  da u svojoj otvorenoj molbi uključite sljedeće informacije:
                  <p></p> <br />
                  <b>Podaci za kontakt:</b> <br />
                  <p></p> Navedite svoje ime, prezime, kontakt broj telefona,
                  e-mail adresu kako bismo mogli stupiti u kontakt s vama i
                  motivacijski tekst. <br />
                  <p></p>
                  <b>Motivacijski tekst:</b> Napišite motivacijski tekst u kojem
                  objašnjavate zašto želite raditi za nas i kako biste
                  doprinijeli našem timu. Navedite svoje ciljeve i ambicije te
                  kako se vidite u našem radnom okruženju. <br />
                  <p></p>
                  <b>Priložite životopis:</b> Navedite vaše osnovne informacije
                  o obrazovanju, radnom iskustvu i relevantnim vještinama.
                  <br />
                  <p></p>
                  <p></p> <b>Reference:</b> Ako imate prethodne radne reference
                  ili preporuke koje smatrate relevantnim, slobodno ih priložite
                  uz otvorenu zamolbu. <br />
                  <p></p>Vašu otvorenu zamolbu možete poslati putem e-maila na{" "}
                  <a href="mailto:sintel@sintel.hr">
                    <b>sintel@sintel.hr</b>
                  </a>{" "}
                  ili putem našeg kontakt obrasca.
                  <b></b> Napominjemo da ćemo pažljivo razmotriti sve pristigle
                  otvorene molbe i ako se ukaže prilika za suradnju, kontaktirat
                  ćemo vas kako bismo dogovorili daljnje korake. <br></br>{" "}
                  <p></p>Hvala vam na interesu i radujemo se vašoj prijavi!{" "}
                  <br /> <p></p>Sretno, <br></br>
                  <p></p> Vaš <span className="sintelTitle">Sintel</span>!
                </p>
                <ShareButton />
              </div>
              <OtvorenaContact />
            </div>
          </div>
        </div>
        {/* End Blog Area */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default withTranslation()(KarijeraOverview);
