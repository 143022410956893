import React, { Fragment, useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Card } from "react-bootstrap";
import { PropagateLoader } from "react-spinners";
import { FiMapPin } from "react-icons/fi";

function Karijera() {
  const [blogPosts, setBlogPosts] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [contentLoaded, setContentLoaded] = useState(false);

  const { id } = useParams();

  useEffect(() => {
    const fetchEntries = async () => {
      setIsLoading(true);

      try {
        // const delay = 800; // 2 seconds

        // setTimeout(() => {
        //   setIsLoading(false);
        // }, delay);

        const response = await fetch("/.netlify/functions/fetchBlogPosts");

        if (!response.ok) {
          throw new Error("Failed to fetch blog posts");
        }
        const data = await response.json();
        const blogPosts = data.items;
        setPhotos(data.includes.Asset);

        if (blogPosts) {
          setBlogPosts(blogPosts);
          setIsLoading(false);
        } else {
          console.log("Blog post not found");
        }

        setPhotos(data.includes.Asset);
        // return () => clearTimeout(timer);
      } catch (error) {
        console.log(error);
      }
    };

    fetchEntries();
  }, []);

  const formatDate = dateString => {
    const date = new Date(dateString);
    const options = {
      day: "numeric",
      month: "long",
      year: "numeric"
    };
    return date.toLocaleDateString("hr-HR", options);
  };

  const publishedCareerPosts = blogPosts
    .filter(post => post.sys.contentType.sys.id === "karijera") // Filter only published posts
    .sort(
      (a, b) => new Date(b.fields.createdAt) - new Date(a.fields.createdAt)
    ); // Sort posts by publishing date in descending order

  return (
    <Fragment>
      {isLoading && (
        <div className="spinner-container">
          {" "}
          {<PropagateLoader color="#0085c0" size={20} />}
        </div>
      )}
      <div className="row">
        {publishedCareerPosts.length === 0 ? (
          <p className="container sintelTitle">
            U ovom trenutku nemamo otvorenih pozicija.
          </p>
        ) : (
          publishedCareerPosts.map((careerPost, i) => {
            return (
              <Card className="text-center card">
                <Card.Body>
                  <Card.Text className="fontMontserrat">
                    {careerPost.fields.naslovOglasa}
                  </Card.Text>
                  <Card.Subtitle className="fontMontserrat blog-meta d-flex justify-content-center align-items-center">
                    <FiMapPin />
                    <p className="pl--5">{careerPost.fields.lokacija}</p>
                  </Card.Subtitle>
                </Card.Body>
                <Card.Footer className="text-muted fontMontserrat">
                  Oglas objavljen: <br />
                  {formatDate(
                    careerPost.fields ? (
                      careerPost.sys.createdAt
                    ) : (
                      <PropagateLoader />
                    )
                  )}
                  <div className="blog-btn fontMontserrat">
                    <Link
                      to={`/karijera-objava/${careerPost.sys.id}`}
                      className="rn-btn mt--10"
                    >
                      Detalji oglasa
                    </Link>
                  </div>
                </Card.Footer>
              </Card>
            );
          })
        )}
      </div>
    </Fragment>
  );
}
export default Karijera;
