import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FiX, FiMenu, FiPhone, FiMail } from "react-icons/fi";
import { withTranslation, useTranslation } from "react-i18next";
import { useLanguage } from "./LanguageContext";

const HeaderFive = ({ logo, color, headerPosition }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { selectedLanguage, changeLanguage } = useLanguage();
  const { t } = useTranslation();

  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const menuTrigger = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenuTrigger = () => {
    setIsMenuOpen(false);
  };

  useEffect(() => {
    const elements = document.querySelectorAll(".has-droupdown > a");
    elements.forEach(element => {
      element.onclick = function() {
        this.parentElement.querySelector(".submenu").classList.toggle("active");
        this.classList.toggle("open");
      };
    });

    window.addEventListener("load", () => {
      console.log("All assets are loaded");
    });

    return () => {
      // Cleanup event listeners if needed
    };
  }, []);

  const toggleLanguageDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleLanguageChange = event => {
    const language = event.target.value;
    changeLanguage(language);
    i18n.changeLanguage(language);
  };

  const PhoneNumberLink = ({ phoneNumber }) => (
    <a href={`tel:${phoneNumber}`}>
      <FiPhone /> {phoneNumber}
    </a>
  );

  const EmailLink = ({ email }) => (
    <a href={`mailto:${email}`}>
      <FiMail /> {email}
    </a>
  );

  let logoUrl;

  if (logo === "light") {
    logoUrl = <img src="/assets/images/logo/sintel-logo.png" alt="Sintel" />;
  } else if (logo === "dark") {
    logoUrl = <img src="/assets/images/logo/sintel-logo.png" alt="Sintel" />;
  } else if (logo === "symbol-dark") {
    logoUrl = <img src="/assets/images/logo/sintel-logo.png" alt="Sintel" />;
  } else if (logo === "all-dark") {
    logoUrl = <img src="/assets/images/logo/sintel-logo.png" alt="Sintel" />;
  } else if (logo === "symbol-light") {
    logoUrl = <img src="/assets/images/logo/sintel-logo.png" alt="Sintel" />;
  } else {
    logoUrl = <img src="/assets/images/logo/sintel-logo.png" alt="Sintel" />;
  }

  return (
    <header
      className={`header-area formobile-menu ${headerPosition} ${color} fixed-top`}
    >
      <div
        className={`header-wrapper ${isMenuOpen ? "menu-open" : ""}`}
        id="header-wrapper"
      >
        <div className="header-container">
          <div className="row align-items-center">
            <div className="col-lg-2 col-md-4 col-6">
              <div className="header-left">
                <div className="logo">
                  <Link to="/">{logoUrl}</Link>
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-md-8 col-6">
              <div className="header-right justify-content-end">
                <nav className={"mainmenunav d-lg-block"}>
                  <ul
                    className="mainmenu"
                    style={{ justifyContent: "flex-start" }}
                  >
                    <li>
                      <Link className="fontMontserrat" to="/revizija">
                        {t("header.audit")}
                      </Link>
                    </li>
                    <li>
                      <Link className="fontMontserrat" to="/o-nama">
                        {t("header.about")}
                      </Link>
                    </li>
                    <li className="has-droupdown">
                      <Link className="fontMontserrat" to="#">
                        {t("header.services")}
                      </Link>
                      <ul className="submenu">
                        <li>
                          <Link className="fontMontserrat" to="/racunovodstvo">
                            {t("header.racunovodstvo")}
                          </Link>
                        </li>{" "}
                        <li>
                          <Link
                            className="fontMontserrat"
                            to="/forenzicno-racunovodstvo"
                          >
                            {t("header.forenzicnoRacunovodstvo")}
                          </Link>
                        </li>{" "}
                        <li>
                          <Link className="fontMontserrat" to="/savjetovanje">
                            {t("header.savjetovanje")}
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link className="fontMontserrat" to="/blog">
                        {t("header.blog")}
                      </Link>
                    </li>
                    <li>
                      <Link className="fontMontserrat" to="/karijera">
                        {t("header.career")}
                      </Link>
                    </li>
                    <li>
                      <Link className="fontMontserrat" to="/kontakt">
                        {t("header.contact")}
                      </Link>
                    </li>
                    <li>
                      <PhoneNumberLink phoneNumber="035/277-001" />
                    </li>
                    <li>
                      <EmailLink email="sintel@sintel.hr" />
                    </li>
                    <li
                      className="has-droupdown"
                      style={{ alignSelf: "center", padding: "10px 0px" }}
                    >
                      <Link
                        className="fontMontserrat"
                        to="#"
                        style={{
                          padding: "5px 10px",
                          border: "2px solid #0085c0",
                          borderRadius: "6px",
                          textTransform: "uppercase"
                        }}
                      >
                        {t("header.ponudaButton")}
                      </Link>
                      <ul className="submenu">
                        <li>
                          <Link className="fontMontserrat" to="/ponuda">
                            {t("header.racunovodstvo")}
                          </Link>
                        </li>{" "}
                        <li>
                          <Link className="fontMontserrat" to="/kontakt">
                            {t("header.audit")}
                          </Link>
                        </li>{" "}
                      </ul>
                    </li>
                    {/* <li
                      className="has-droupdown"
                      style={{
                        alignSelf: "center",
                        padding: "10px 0px 10px 0px",
                        cursor: "pointer"
                      }}
                      onMouseEnter={menuTrigger}
                      onMouseLeave={menuTrigger}
                    >
                      <div
                        className={`borderButton fontMontserrat ${
                          isMenuOpen ? "open" : ""
                        }`}
                        style={{
                          padding: "7px",
                          alignSelf: "center",
                          fontSize: "12px"
                        }}
                      >
                        Zatražite ponudu
                      </div>
                      <ul className="submenu">
                        <li>
                          <Link className="fontMontserrat" to="/ponuda">
                            {t("header.racunovodstvo")}
                          </Link>
                        </li>{" "}
                        <li>
                          <Link className="fontMontserrat" to="/kontakt">
                            {t("header.audit")}
                          </Link>
                        </li>{" "}
                      </ul>
                    </li> */}
                    <li style={{ alignSelf: "center", marginLeft: "0px" }}>
                      <div>
                        <select
                          value={currentLanguage}
                          onChange={handleLanguageChange}
                          style={{
                            height: "25px",
                            padding: "0px",
                            color: "#5ebce6",
                            border: "none"
                          }}
                        >
                          <option value="en" style={{ backgroundImage: "" }}>
                            ENG
                          </option>
                          <option value="hr">HR</option>
                          {/* Add more language options */}
                        </select>
                      </div>
                    </li>
                  </ul>
                </nav>
                {/* Start Humberger Menu  */}
                <div className="humberger-menu d-block d-lg-none pl--20">
                  <span
                    onClick={menuTrigger}
                    className="menutrigger text-white"
                  >
                    <FiMenu />
                  </span>
                </div>
                <div className="close-menu d-block d-lg-none">
                  <span onClick={closeMenuTrigger} className="closeTrigger">
                    <FiX />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
export default withTranslation()(HeaderFive);
