import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enTranslation from "./locales/en/translation.json";
import hrTranslation from "./locales/hr/translation.json";
import LanguageDetector from "i18next-browser-languagedetector";

// Configure i18n instance
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // Supported languages
    supportedLngs: ["hr", "en"],

    // Fallback language
    fallbackLng: "hr",
    lng: "hr",
    // Load translations
    resources: {
      hr: {
        translation: hrTranslation
      },
      en: {
        translation: enTranslation
      }
    },

    // Language detection options
    detection: {
      order: ["localStorage", "navigator"],
      caches: ["localStorage"]
    },

    // React specific options
    react: {
      wait: true,
      useSuspense: false
    }
  });

export default i18n;
