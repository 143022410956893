import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import PageHelmet from "../component/common/Helmet";
import { FiClock, FiEdit2 } from "react-icons/fi";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import HeaderFive from "../component/header/HeaderFive";
import Footer from "../component/footer/Footer";
import { PropagateLoader } from "react-spinners";
import ContactTwo from "./contact/ContactTwo";
import ShareButton from "../elements/ShareButton";
import { withTranslation, useTranslation } from "react-i18next";

// import axios from "axios";

function BlogDetalj() {
  const [singleBlogPost, setSingleBlogPost] = useState(null);
  const [photos, setPhotos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const { t } = useTranslation();

  const { slug } = useParams();
  const history = useHistory();

  const handleGoBack = () => {
    history.goBack();
  };

  useEffect(() => {
    const fetchEntries = async () => {
      setIsLoading(true);

      try {
        const response = await fetch("/.netlify/functions/fetchBlogPosts");

        if (!response.ok) {
          throw new Error("Failed to fetch blog posts");
        }

        const data = await response.json();
        const blogPost = data.items.find(item => item.fields.slug === slug); // Use 'slug' to find the blog post

        if (blogPost) {
          setSingleBlogPost(blogPost);
        } else {
          throw new Error("Blog post not found");
        }

        setPhotos(data.includes.Asset);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setError(true);
        setIsLoading(false);
      }
    };

    fetchEntries();
  }, [slug]);

  const formatDate = dateString => {
    const date = new Date(dateString);
    const options = {
      day: "numeric",
      month: "long",
      year: "numeric"
    };
    return date.toLocaleDateString("hr-HR", options);
  };

  if (isLoading) {
    return (
      <div className="spinner-container">
        <PropagateLoader color="#0085c0" size={20} />
      </div>
    );
  }

  if (error) {
    // Redirect to the error page
    history.push("/error");
    return null;
  }

  if (!singleBlogPost) {
    return <div className="text-center">Blog post not found</div>;
  }
  const dynamicText = singleBlogPost.fields.title;
  const contactTitle = (
    <span
      dangerouslySetInnerHTML={{ __html: t("blog.moreInfo", { dynamicText }) }}
    />
  );

  const keywords = singleBlogPost.fields.keywords || [];
  const hasKeywords = keywords.length > 0;

  return (
    <React.Fragment>
      <HeaderFive
        headerPosition="header--static logoresize"
        logo="all-dark"
        color="color-black"
      />
      <PageHelmet pageTitle={singleBlogPost.fields.title} />
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="blog-single-page-title text-center pt--100">
              <h2 className="title theme-gradient fontMontserrat">
                {singleBlogPost.fields.title}
              </h2>
              <ul className="blog-meta d-flex justify-content-center align-items-center">
                <li className="flexStart">
                  <FiClock />
                  {formatDate(singleBlogPost.sys.createdAt)}
                </li>
                <li className="flexStart">
                  <FiEdit2 />
                  <span
                    className="alignCenter"
                    style={{ color: "#0085c0", fontSize: "medium" }}
                  >
                    {singleBlogPost.fields.author}
                  </span>
                  {singleBlogPost.fields.titula1 ? (
                    <p className="blogTitula alignCenter">
                      {singleBlogPost.fields.titula1}
                    </p>
                  ) : null}
                  {singleBlogPost.fields.titula2 ? (
                    <p className="blogTitula alignCenter">
                      {singleBlogPost.fields.titula2}
                    </p>
                  ) : null}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="rn-blog-details pt--50 pb--70 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="inner-wrapper">
                <div className="inner fontMontserrat justifyText">
                  <ReactMarkdown>
                    {singleBlogPost.fields.blogPost}
                  </ReactMarkdown>
                  {hasKeywords && (
                    <div className="keywords-section">
                      {keywords.map((keyword, index) => (
                        <span className="keyword-box" key={index}>
                          <h6>{keyword}</h6>
                        </span>
                      ))}
                    </div>
                  )}
                  <ShareButton />
                  <button
                    className="blog-button-style--2 btn-solid"
                    onClick={handleGoBack}
                  >
                    {t("blog.back")}
                  </button>
                  <ContactTwo contactTitle={contactTitle} colSize={12} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default withTranslation()(BlogDetalj);
