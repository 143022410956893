import React from "react";
import GoogleMapReact from "google-map-react";

const createMarkers = ({ map, maps }) => {
  const markers = [
    { id: 1, lat: 45.16058198191611, lng: 18.012364046898515 }, // Slavonski Brod
    { id: 2, lat: 45.558253, lng: 18.677914 }, // Osijek
    { id: 3, lat: 45.29621439042069, lng: 18.80875556243584 } // Vinkovic
  ];

  markers.forEach(marker => {
    new maps.Marker({
      position: { lat: marker.lat, lng: marker.lng },
      map,
      title: `Marker ${marker.id}`
    });
  });
};

const Map = () => {
  const center = { lat: 45.34, lng: 18.57 };
  const zoom = window.innerWidth < 768 ? 8.5 : 10;

  const googleMapsAPIKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

  return (
    <GoogleMapReact
      bootstrapURLKeys={{ key: googleMapsAPIKey }}
      defaultCenter={center}
      defaultZoom={zoom}
      onGoogleApiLoaded={createMarkers}
      yesIWantToUseGoogleMapApiInternals={true}
    ></GoogleMapReact>
  );
};

export default Map;
