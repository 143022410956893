import React, { Component } from "react";
import OtvorenaZamolba from "./OtvorenaZamolba";

function OtvorenaContact({ jobTitle }) {
  return (
    <div className="contact-form--1">
      <div className="container">
        <div className="row row--35 align-items-center">
          <div className="col-lg-6 order-2 order-lg-1">
            <div className="section-title text-left mb--50">
              <h3 className="title fontMontserrat">
                Pošaljite nam svoju zamolbu!
              </h3>
            </div>
            <div className="form-wrapper">
              <OtvorenaZamolba jobTitle={jobTitle} />
            </div>
          </div>
          <div className="col-lg-6 order-1 order-lg-2">
            <div className="thumbnail mb_md--30 mb_sm--30">
              <img src="/assets/images/about/kontakt.png" alt="Sintel" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default OtvorenaContact;
