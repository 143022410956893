import React from "react";
import PonudaForm from "./PonudaForm";
import { withTranslation, useTranslation } from "react-i18next";
import Slider from "react-slick";
import { FiCheck } from "react-icons/fi";

function PonudaWrapper({ contactTitle, colSize, textColor }) {
  const { t } = useTranslation();

  var savjetovanjeItems = [
    t("pocetna.description4.item1"),
    t("pocetna.description4.item2"),
    t("pocetna.description4.item3")
  ];

  const SlideList = [
    // {
    //   textPosition: "text-right",
    //   bgImage: "bg_image--34",
    //   category: "",
    //   title: t("pocetna.title1"),
    //   description: t("pocetna.description1"),
    //   buttonText: t("pocetna.buttonPonuda"),
    //   buttonLink: "/ponuda"
    // },
    {
      textPosition: "text-left",
      bgImage: "bg_image--34",
      category: "",
      title: t("pocetna.title2"),
      description: t("pocetna.description2"),
      buttonText: t("pocetna.buttonPonuda"),
      buttonLink: "/ponuda"
    },
    {
      textPosition: "text-right",
      bgImage: "bg_image--34",
      category: "",
      title: t("pocetna.title3"),
      description: t("pocetna.description3"),
      buttonText: t("pocetna.buttonPonuda"),
      buttonLink: "/ponuda"
    },
    {
      textPosition: "text-left",
      bgImage: "bg_image--34",
      category: "",
      title: t("pocetna.title4"),
      description: (
        <ul className="list-style--1">
          {savjetovanjeItems.map((name, index) => {
            return (
              <li key={index}>
                <FiCheck /> {name}
              </li>
            );
          })}
        </ul>
      ),
      buttonText: t("pocetna.buttonPonuda"),
      buttonLink: "/ponuda"
    }
  ];

  const sliderSettings = {
    arrows: false,
    dots: false,
    pauseOnHover: false,
    infinite: true,
    speed: 4000,
    autoplay: true,
    fade: true,
    variableWidth: false,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const blogContactWide = `col-lg-${
    colSize ? colSize : 6
  } order-1 order-lg-1 pt--20`;
  return (
    <div className="contact-form--1">
      <div
        className="container pl--0"
        // style={{
        //   display: "flex",
        //   justifyContent: "center",
        //   alignItems: "center"
        // }}
      >
        <div className="row row--35 align-items-center">
          <div className={blogContactWide}>
            <div className="section-title text-left mb--50">
              <h3
                className="title pb--5 fontMontserrat"
                style={{
                  lineHeight: "40px",
                  color: textColor ? textColor : "black"
                }}
              >
                {contactTitle ? contactTitle : t("contact.ponuda")}
              </h3>
            </div>
            <div className="form-wrapper">
              <PonudaForm />
            </div>
          </div>
          <div className={blogContactWide}>
            <Slider className="rn-slick-dot dot-light" {...sliderSettings}>
              {SlideList.map((value, index) => (
                <div
                  className={`slide slide-style-2 slider-box-content without-overlay d-flex align-items-center justify-content-center`}
                  key={index}
                  style={{ paddingTop: "0px" }}
                >
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12">
                        <div
                          className={`inner ${value.textPosition}`}
                          style={{ paddingTop: "0px" }}
                        >
                          {value.category ? <span>{value.category}</span> : ""}
                          {value.title ? (
                            <h1
                              className="title fontMontserrat"
                              style={{ fontSize: "25px", lineHeight: "50px" }}
                            >
                              {value.title}
                            </h1>
                          ) : (
                            ""
                          )}
                          {value.description ? (
                            <p
                              className="description fontMontserrat"
                              style={{
                                fontSize: "15px",
                                padding: "0",
                                lineHeight: "30px"
                              }}
                            >
                              {value.description}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
}
export default withTranslation()(PonudaWrapper);
