import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Footer from "../component/footer/Footer";
import HeaderFive from "../component/header/HeaderFive";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { withTranslation } from "react-i18next";

class Racunovodstvo extends Component {
  constructor() {
    super();
    this.state = {
      tab1: 0,
      tab2: 0,
      tab3: 0,
      tab4: 0,
      isOpen: false
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }

  renderDescription(text) {
    return (
      <p
        className="fontMontserrat justifyText"
        dangerouslySetInnerHTML={{ __html: this.props.t(text) }}
      ></p>
    );
  }
  render() {
    return (
      <React.Fragment>
        {/* Start Pagehelmet  */}
        <PageHelmet pageTitle="Računovodstvo | Knjigovodstvo" />
        {/* End Pagehelmet  */}

        <HeaderFive
          headerPosition="header--static logoresize"
          logo="all-dark"
          color="color-black"
        />

        {/* Start Page Wrapper */}
        <div className="rn-service-details ptb--120 bg_color--5 fingerprintPattern">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="sercice-details-content">
                  <div className="inner">
                    <Tabs>
                      <div className="row text-center">
                        <div className="col-lg-12">
                          <div className="tablist-inner">
                            <TabList className="pv-tab-button text-center mt--0">
                              <Tab>
                                <span className="fontMontserrat">
                                  {this.props.t("Racunovodstvo.tab1")}
                                </span>
                              </Tab>
                              <Tab>
                                <span className="fontMontserrat">
                                  {this.props.t("Racunovodstvo.tab2")}
                                </span>
                              </Tab>
                              <Tab>
                                <span className="fontMontserrat">
                                  {this.props.t("Racunovodstvo.tab3")}
                                </span>
                              </Tab>
                              <Tab>
                                <span className="fontMontserrat">
                                  {this.props.t("Racunovodstvo.tab4")}
                                </span>
                              </Tab>
                            </TabList>
                          </div>
                        </div>
                      </div>
                      <TabPanel className="row row--35">
                        <div className="col-12">
                          <div className="details mt_md--30 mt_sm--30 justifyText">
                            <p className="fontMontserrat">
                              {this.props.t("Racunovodstvo.tabContent1a")}
                              <br></br>
                            </p>
                            <p></p>
                            <p className="fontMontserrat">
                              {this.props.t("Racunovodstvo.tabContent1b")}
                              <br></br>
                            </p>
                            <p className="fontMontserrat">
                              {this.props.t("Racunovodstvo.tabContent1c")}
                              <br></br>
                            </p>
                          </div>
                        </div>
                      </TabPanel>
                      <TabPanel className="row row--35">
                        <div className="col-12">
                          <div className="details mt_md--30 mt_sm--30 justifyText">
                            <p className="fontMontserrat">
                              {this.props.t("Racunovodstvo.tabContent2")}
                              <br></br>
                            </p>
                            <p></p>
                            <b className="fontMontserrat">
                              {this.props.t("Racunovodstvo.tabContent2a")}
                            </b>
                            <ul className="liststyle fontMontserrat">
                              <li>
                                • {this.props.t("Racunovodstvo.tabContent2b")}
                              </li>
                              <li>
                                • {this.props.t("Racunovodstvo.tabContent2c")}
                              </li>
                              <li>
                                • {this.props.t("Racunovodstvo.tabContent2d")}
                              </li>
                            </ul>
                            <br></br>
                            <p></p>
                            <b className="fontMontserrat">
                              {this.props.t("Racunovodstvo.tabContent2dd")}
                            </b>
                            <ul className="liststyle fontMontserrat">
                              <li>
                                • {this.props.t("Racunovodstvo.tabContent2e")}
                              </li>
                              <li>
                                • {this.props.t("Racunovodstvo.tabContent2f")}
                              </li>
                              <li>
                                • {this.props.t("Racunovodstvo.tabContent2g")}
                              </li>
                            </ul>
                            <p></p>
                            <br></br>
                            <b className="fontMontserrat">
                              {this.props.t("Racunovodstvo.tabContent2h")}
                            </b>
                            <ul className="liststyle fontMontserrat">
                              <li>
                                • {this.props.t("Racunovodstvo.tabContent2i")}
                              </li>
                              <li>
                                • {this.props.t("Racunovodstvo.tabContent2j")}
                              </li>
                              <li>
                                • {this.props.t("Racunovodstvo.tabContent2k")}
                              </li>
                              <li>
                                • {this.props.t("Racunovodstvo.tabContent2l")}
                              </li>
                            </ul>
                            <p></p>
                            <br></br>
                            <b>{this.props.t("Racunovodstvo.tabContent2m")}</b>
                            <ul className="liststyle fontMontserrat">
                              <li>
                                • {this.props.t("Racunovodstvo.tabContent2n")}
                              </li>
                              <li>
                                • {this.props.t("Racunovodstvo.tabContent2o")}
                              </li>
                              <li>
                                • {this.props.t("Racunovodstvo.tabContent2p")}
                              </li>
                              <li>
                                • {this.props.t("Racunovodstvo.tabContent2q")}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </TabPanel>
                      <TabPanel className="row row--35">
                        <div className="col-12">
                          <div className="details mt_md--30 mt_sm--30 fontMontserrat justifyText">
                            <p>
                              {this.renderDescription(
                                "Racunovodstvo.tabContent3"
                              )}
                              <br></br>
                            </p>
                            <p></p>
                            <p>
                              {this.renderDescription(
                                "Racunovodstvo.tabContent3a"
                              )}
                              <br></br>
                            </p>
                            <p></p>
                            <ul className="liststyle fontMontserrat">
                              <li>
                                • {this.props.t("Racunovodstvo.tabContent3b")}
                              </li>
                              <li>
                                • {this.props.t("Racunovodstvo.tabContent3c")}
                              </li>
                              <li>
                                • {this.props.t("Racunovodstvo.tabContent3d")}
                              </li>
                              <li>
                                • {this.props.t("Racunovodstvo.tabContent3e")}
                              </li>
                            </ul>
                            <p></p>
                            <p>
                              {this.props.t("Racunovodstvo.tabContent3f")}
                              <br></br>
                            </p>
                            <ul className="liststyle fontMontserrat">
                              <li>
                                • {this.props.t("Racunovodstvo.tabContent3g")}
                              </li>
                              <li>
                                • {this.props.t("Racunovodstvo.tabContent3h")}
                              </li>
                              <li>
                                • {this.props.t("Racunovodstvo.tabContent3i")}
                              </li>
                              <li>
                                • {this.props.t("Racunovodstvo.tabContent3j")}
                              </li>
                              <li>
                                • {this.props.t("Racunovodstvo.tabContent3k")}
                              </li>
                              <li>
                                • {this.props.t("Racunovodstvo.tabContent3l")}
                              </li>
                              <li>
                                • {this.props.t("Racunovodstvo.tabContent3m")}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </TabPanel>
                      <TabPanel className="row row--35">
                        <div className="col-12">
                          <div className="details mt_md--30 mt_sm--30 fontMontserrat justifyText">
                            <p>
                              {this.props.t("Racunovodstvo.tabContent4a")}
                              <br></br>
                            </p>
                            <p></p>
                            <ul className="liststyle fontMontserrat">
                              <li>
                                • {this.props.t("Racunovodstvo.tabContent4b")}
                              </li>
                              <li>
                                • {this.props.t("Racunovodstvo.tabContent4c")}
                              </li>
                              <li>
                                • {this.props.t("Racunovodstvo.tabContent4d")}
                              </li>
                              <li>
                                • {this.props.t("Racunovodstvo.tabContent4e")}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </TabPanel>
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Page Wrapper */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default withTranslation()(Racunovodstvo);
