import React, { useState, useEffect } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import HeaderFive from "../component/header/HeaderFive";
import Footer from "../component/footer/Footer";
import { withTranslation, useTranslation } from "react-i18next";
import "dotenv/config";

function IzjavaOKolacicima() {
  const history = useHistory();
  const { t } = useTranslation();
  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <React.Fragment>
      <HeaderFive
        headerPosition="header--static logoresize"
        logo="all-dark"
        color="color-black"
      />
      <React.Fragment>
        <PageHelmet pageTitle={t("cookieStatement.title")} />

        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="blog-single-page-title text-center pt--100">
                <h2 className="title theme-gradient fontMontserrat">
                  {t("cookieStatement.heading")}
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="rn-blog-details pt--50 pb--70 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner-wrapper">
                  <div className="inner fontMontserrat justifyText">
                    <p>{t("cookieStatement.text1")}</p>
                    <h3>{t("cookieStatement.whatIsCookie")}</h3>
                    <p>
                      {t("cookieStatement.whatIsCookieText")} <br />
                      <p></p> {t("cookieStatement.infoText")}
                    </p>
                    <h3>{t("cookieStatement.howToDisableCookies")}</h3>
                    <p>{t("cookieStatement.howToDisableCookiesText")}</p>
                    <h3>{t("cookieStatement.whatAreSessionCookies")}</h3>
                    <p>{t("cookieStatement.whatAreSessionCookiesText")}</p>
                    <h3>{t("cookieStatement.whatArePersistentCookies")}</h3>
                    <p>{t("cookieStatement.whatArePersistentCookiesText")}</p>
                    <h3>{t("cookieStatement.whatAreFirstPartyCookies")}</h3>
                    <p>{t("cookieStatement.firstPartyCookiesText")}</p>
                    <h3>{t("cookieStatement.whatAreThirdPartyCookies")}</h3>
                    <p>{t("cookieStatement.thirdPartyCookiesText")}</p>
                    <h3>{t("cookieStatement.doesSintelUseCookies")}</h3>
                    <p>{t("cookieStatement.sintelUsesCookiesText")}</p>
                    <button
                      className="blog-button-style--2 btn-solid "
                      onClick={handleGoBack}
                    >
                      {t("privacy.backButton")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    </React.Fragment>
  );
}
export default IzjavaOKolacicima;
