import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  FaTwitter,
  FaInstagram,
  FaFacebookF,
  FaLinkedinIn
} from "react-icons/fa";
import { withTranslation } from "react-i18next";

const SocialShare = [
  { Social: <FaFacebookF />, link: "https://www.facebook.com/Sintel000" },
  // { Social: <FaLinkedinIn />, link: "https://www.linkedin.com/" },
  {
    Social: <FaInstagram />,
    link: "https://www.instagram.com/revizija_sintel/"
  },
  {
    Social: <FaLinkedinIn />,
    link: "https://www.linkedin.com/company/sintel-d-o-o/?originalSubdomain=hr"
  }
];

class Footer extends Component {
  render() {
    return (
      <React.Fragment>
        <footer className="footer-area">
          <div className="footer-wrapper">
            <div className="row align-items-end row--0">
              <div className="col-lg-6">
                <div className="footer-left">
                  <div className="inner">
                    <span className="fontMontserrat">
                      {this.props.t("footer.future")}
                    </span>
                    <h2 className="fontMontserrat">
                      SINTEL <br></br>
                    </h2>
                    <Link
                      className="rn-button-style--2 fontMontserrat"
                      to="/kontakt"
                    >
                      <span>{this.props.t("footer.contact")}</span>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="footer-right">
                  <div className="row">
                    {/* Start Single Widget  */}
                    <div className="col-lg-4 col-sm-6 col-12 pb--10">
                      <div className="footer-link">
                        <h4 className="fontMontserrat">
                          {this.props.t("contact.contact")}
                        </h4>
                        <ul className="ft-link fontMontserrat">
                          <li>
                            <b>Email:</b> sintel@sintel.hr<br></br>
                            <b>Slavonski Brod:</b>
                            <br></br>
                            <b>TEL:</b>+385 (0)35 277 001
                          </li>
                          <li>
                            <b>Osijek:</b>
                            <br></br>
                            <b>TEL:</b>+385 (0)31 499 218
                            <br></br>
                            <b>FAX:</b>+385 (0)31 496 491
                          </li>
                          <li>
                            <b>Vinkovci:</b>
                            <br></br>
                            <b>TEL:</b>+385 (0)32 541 948
                          </li>
                        </ul>
                      </div>
                    </div>
                    <p></p>

                    <div className="col-lg-4 col-sm-6 col-12 pb--10">
                      <div className="footer-link">
                        <h4 className="fontMontserrat">
                          {this.props.t("contact.locations")}
                        </h4>
                        <ul className="ft-link fontMontserrat">
                          <li>
                            <b>{this.props.t("contact.headquarters")}</b>{" "}
                            <br></br>
                          </li>
                          <li>
                            {" "}
                            • &nbsp; Naselje Slavonija I 4/7 <br></br>
                            35000 Slavonski Brod
                          </li>
                          <li>
                            <b>{this.props.t("contact.offices")}</b> <br></br>
                          </li>
                          {/* <li>
                            {" "}
                            • &nbsp; Naselje Slavonija I 4/7 <br></br>
                            35000 Slavonski Brod
                          </li> */}
                          <li>
                            {" "}
                            • &nbsp; Ulica Hrvatske Republike 33 <br></br>
                            31000 Osijek
                          </li>
                          <li>
                            {" "}
                            • &nbsp; Kralja Zvonimira 69<br></br>
                            32100 Vinkovci
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12 pb--10">
                      <div className="footer-link">
                        <h4 className="fontMontserrat">Sintel d.o.o.</h4>
                        <ul className="ft-link fontMontserrat">
                          <li>OIB 20356963200</li>
                          <li>MBS: 030113545</li>
                          <li>IBAN: HR1243546786970898765</li>
                          <li>KUNSKI/DEVIZNI: 123546789765</li>
                          <li>SWIFT/BIC: RZB XYDAASX</li>
                        </ul>
                      </div>
                    </div>
                    {/* End Single Widget  */}
                    <div className="col-lg-12">
                      <div className="copyright-text">
                        <p className="fontMontserrat font10">
                          <ul
                            className=" d-flex justify-content-center liststyle"
                            style={{ lineHeight: "15px", alignItems: "center" }}
                          >
                            <li className="pr--10">
                              <Link to={`/izjava-o-privatnosti`}>
                                {this.props.t("privacy.title")}
                              </Link>
                            </li>
                            <li className="pr--10">
                              <Link to={`/izjava-o-kolacicima`}>
                                {this.props.t("cookieStatement.title")}
                              </Link>
                            </li>
                            <li className="pr--10">
                              <Link to={`/pristup-podacima`}>
                                {this.props.t("dataAccess.title")}
                              </Link>
                            </li>
                            <li>
                              © {new Date().getFullYear()}{" "}
                              {this.props.t("allRR.text")}
                            </li>

                            {SocialShare.map((val, i) => (
                              <li
                                key={i}
                                className="social-share rn-lg-size d-flex justify-content-center liststyle"
                              >
                                <a
                                  href={`${val.link}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {val.Social}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}
export default withTranslation()(Footer);
