/* global FB */
import React, { useEffect } from "react";

const FacebookChat = () => {
  const pageId = process.env.REACT_APP_PAGE_ID;

  useEffect(() => {
    window.fbAsyncInit = function() {
      FB.init({
        xfbml: true,
        version: "v17.0",
        locale: "hr_HR" // Set the desired language code here
      });
    };

    (function(d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/hr_HR/sdk/xfbml.customerchat.js"; // Update the language code here
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  }, []);

  return (
    <>
      <div id="fb-root" />
      <div
        id="fb-customer-chat"
        class="fb-customerchat"
        attribution="biz_inbox"
        page_id={pageId}
      />
    </>
  );
};

export default FacebookChat;
