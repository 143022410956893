import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import { withTranslation, useTranslation } from "react-i18next";
import Skeleton from "@mui/material/Skeleton";

function BlogPost(textColor) {
  const [blogPosts, setBlogPosts] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const { t } = useTranslation();

  useEffect(() => {
    const fetchEntries = async () => {
      setIsLoading(true);

      try {
        const delay = 1200; // 2 seconds
        setTimeout(() => {
          setIsLoading(false);
        }, delay);

        const response = await fetch("/.netlify/functions/fetchBlogPosts");

        if (!response.ok) {
          throw new Error("Failed to fetch blog posts");
        }
        const data = await response.json();

        setBlogPosts(data.items ? data.items : []);
        setPhotos(
          data.includes && data.includes.Asset ? data.includes.Asset : []
        );
      } catch (error) {
        console.log(error);
      }
    };

    fetchEntries();
  }, []);

  const lastThreePublishedPosts = blogPosts
    .filter(post => post.sys.contentType.sys.id !== "karijera")
    .sort((a, b) => new Date(b.fields.createdAt) - new Date(a.fields.createdAt))
    .slice(0, 3);

  return (
    <div className="col-lg-3 col-md-6 col-12" style={{ display: "contents" }}>
      {lastThreePublishedPosts.map(post => {
        // Find the photo associated with the post based on sys.id
        if (post.sys.contentType.sys.id !== "karijera") {
          //display only blogs, not careers
          const photo = photos.find(
            item => item.sys.id === post.fields.blogPhoto.sys.id
          );

          if (!photo) {
            return null; // Skip rendering if the associated photo is not found
          }

          const { title, file } = photo.fields;

          return (
            <div
              className="col-lg-4 col-md-6 col-12 mb--10"
              style={{ position: "relative" }}
              key={post.sys.id}
            >
              <div className="blog">
                <div className="thumbnail">
                  {isLoading && (
                    <Skeleton
                      animation="wave"
                      variant="rectangular"
                      width={"100%"}
                      height={150}
                    />
                  )}
                  {!isLoading && (
                    <img className="w-100" src={`${file.url}`} alt={title} />
                  )}
                </div>
              </div>
              <h4
                className="blogtype ellipsis fontMontserrat pt--20"
                style={{
                  color:
                    textColor && textColor.textColor
                      ? textColor.textColor
                      : "black"
                }}
              >
                {isLoading ? (
                  <Skeleton animation="wave" height={50} />
                ) : (
                  post.fields.title
                )}
              </h4>
              <p
                className="ellipsis fontMontserrat"
                style={{
                  marginBottom: "50px",
                  color:
                    textColor && textColor.textColor
                      ? textColor.textColor
                      : "black"
                }}
              >
                <ReactMarkdown>
                  {isLoading ? (
                    <Skeleton animation="wave" height={100} width={"100%"} />
                  ) : (
                    post.fields.blogPost
                  )}
                </ReactMarkdown>
              </p>
              {/* style={{ paddingBottom: "40px", marginBottom: "10px" }} */}
              <div
                className="blog-btn fontMontserrat"
                style={{ position: "absolute", bottom: "0" }}
              >
                {isLoading ? (
                  <Skeleton animation="wave" width={150} height={80} />
                ) : (
                  <Link
                    to={`/blog-tekst/${post.fields.slug}`}
                    className="rn-btn"
                    style={{
                      color:
                        textColor && textColor.textColor
                          ? textColor.textColor
                          : "black"
                    }}
                  >
                    {t("blog.readMore")}
                  </Link>
                )}
              </div>
            </div>
          );
        }
      })}
    </div>
  );
}

export default withTranslation()(BlogPost);
