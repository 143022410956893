import React, { useState, useEffect } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import HeaderFive from "../component/header/HeaderFive";
import Footer from "../component/footer/Footer";
import { withTranslation, useTranslation } from "react-i18next";
import "dotenv/config";

function IzjavaOPrivatnosti() {
  const history = useHistory();
  const { t } = useTranslation();
  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <React.Fragment>
      <HeaderFive
        headerPosition="header--static logoresize"
        logo="all-dark"
        color="color-black"
      />
      <React.Fragment>
        <PageHelmet pageTitle={t("dataAccess.pageTitle")} />
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="blog-single-page-title text-center pt--100">
                <h2 className="title theme-gradient fontMontserrat">
                  {t("privacy.title")}
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="rn-blog-details pt--50 pb--70 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner-wrapper">
                  <div className="inner fontMontserrat justifyText">
                    <p>{t("privacy.paragraph1")}</p>
                    <ul className="liststyleFooter">
                      <li>{t("privacy.listItem1")}</li>
                      <li>{t("privacy.listItem2")}</li>
                      <li>{t("privacy.listItem3")}</li>
                    </ul>
                    <p>{t("privacy.paragraph2")}</p>
                    <p>{t("privacy.paragraph3")}</p>
                    <p>{t("privacy.paragraph4")}</p>
                    <p>{t("privacy.paragraph5")}</p>
                    <button
                      className="blog-button-style--2 btn-solid "
                      onClick={handleGoBack}
                    >
                      {t("privacy.backButton")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        s
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}
        <Footer />
      </React.Fragment>
    </React.Fragment>
  );
}
export default IzjavaOPrivatnosti;
