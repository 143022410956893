import React, { Component } from "react";
import { Link } from "react-router-dom";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import HeaderFive from "../component/header/HeaderFive";
import Footer from "../component/footer/Footer";
import { withTranslation } from "react-i18next";

class ONama extends Component {
  renderDescription(text) {
    return (
      <p
        className="fontMontserrat justifyText"
        dangerouslySetInnerHTML={{ __html: this.props.t(text) }}
      ></p>
    );
  }
  render() {
    return (
      <React.Fragment>
        <PageHelmet pageTitle="O nama" />

        <HeaderFive
          headerPosition="header--static logoresize"
          logo="all-dark"
          color="color-black"
        />

        {/* Start About Area  */}
        <div className="rn-about-area ptb--120 bg_color--5 fingerprintPattern">
          <div className="rn-about-wrapper">
            <div className="container">
              <div className="row row--35 align-items-center">
                <div className="col-lg-12">
                  <div className="about-inner inner">
                    <div className="section-title">
                      <h2 className="title fontMontserrat">
                        {this.props.t("ONama.pageTitle")}
                      </h2>
                    </div>
                    <div className="row mt--30">
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                        <div className="about-us-list fontMontserrat">
                          {this.renderDescription("ONama.aboutContent")}
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="thumbnail pt--20">
                          <div className="image">
                            <img
                              src="/assets/images/about/oNama.png"
                              alt="Računovodstvo - Revizija Sintel"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End About Area  */}
        <div className="rn-about-area ptb--120 bg_color--5 fingerprintPattern">
          <div className="rn-about-wrapper">
            <div className="container">
              <div className="row row--35 align-items-center">
                <div className="col-lg-12">
                  <div className="about-inner inner">
                    <div className="section-title">
                      <h2 className="title fontMontserrat">
                        {this.props.t("ONama.certificatesSectionTitle")}
                      </h2>
                    </div>
                    <div className="row mt--30">
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                        <div className="about-us-list fontMontserrat">
                          <p>
                            <b>
                              {" "}
                              {this.renderDescription(
                                "ONama.certificatesContent"
                              )}
                            </b>
                          </p>
                          <ul className="liststyle fontMontserrat">
                            <li>{this.props.t("ONama.certificatesList1")}</li>
                            <li>{this.props.t("ONama.certificatesList2")}</li>
                            <li>{this.props.t("ONama.certificatesList3")}</li>
                          </ul>
                          <p></p>
                          <p>
                            {this.renderDescription("ONama.certificatesInfo")}
                          </p>
                        </div>
                        <Link
                          className="rn-button-style--1 btn-white fontMontserrat mt--20"
                          to="/kontakt"
                        >
                          {this.props.t("ONama.contactLinkText")}
                        </Link>
                      </div>
                      <div className="col-lg-6 col-12 whiteFont certifikatCenter">
                        <img
                          src="/assets/images/about/certifikat.png"
                          alt="Revizija Sintel - Certifikat"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default withTranslation()(ONama);
