import React, { Component } from "react";
import ContactForm from "./ContactForm";
import { withTranslation, useTranslation } from "react-i18next";

function ContactTwo({ contactTitle, colSize, textColor }) {
  const { t } = useTranslation();

  const blogContactWide = `col-lg-${
    colSize ? colSize : 6
  } order-1 order-lg-1 pt--20`;
  return (
    <div className="contact-form--1">
      <div className="container pl--0">
        <div className="row row--35 align-items-center">
          <div className={blogContactWide}>
            <div className="section-title text-left mb--50">
              <h3
                className="title pb--5 fontMontserrat"
                style={{
                  lineHeight: "40px",
                  color: textColor ? textColor : "black"
                }}
              >
                {contactTitle ? contactTitle : t("contact.contactUs")}
              </h3>
            </div>
            <div className="form-wrapper">
              <ContactForm />
            </div>
          </div>
          {!contactTitle && (
            <div className="col-lg-6 order-1 order-lg-2">
              <div className="footer-right">
                <div className="row">
                  {/* Start Single Widget  */}
                  <div className="col-lg-6 col-sm-6 col-12 pb--10">
                    <div className="footer-link">
                      <h4 className="fontMontserrat sintelTitle">
                        {t("contact.contact")}
                      </h4>
                      <ul className="ft-link fontMontserrat sintelTitle">
                        <li>
                          <b>Email: </b>sintel@sintel.hr<br></br>
                          <b>Slavonski Brod:</b>
                          <br></br>
                          <b>TEL:</b>+385 (0)35 277 001
                        </li>
                        <li>
                          <b>Osijek:</b>
                          <br></br>
                          <b>TEL:</b>+385 (0)31 499 218
                          <br></br>
                          <b>FAX:</b>+385 (0)31 496 491
                        </li>
                        <li>
                          <b>Vinkovci:</b>
                          <br></br>
                          <b>TEL:</b>+385 (0)32 541 948
                        </li>
                      </ul>
                    </div>
                  </div>
                  <p></p>

                  <div className="col-lg-6 col-sm-6 col-12 pb--10">
                    <div className="footer-link">
                      <h4 className="fontMontserrat sintelTitle">
                        {t("contact.locations")}
                      </h4>
                      <ul className="ft-link fontMontserrat sintelTitle">
                        <li>
                          <b>{t("contact.headquarters")}</b> <br></br>
                        </li>
                        <li>
                          {" "}
                          • &nbsp; Naselje Slavonija I 4/7 <br></br>
                          35000 Slavonski Brod
                        </li>
                        <li>
                          <b>{t("contact.offices")}</b> <br></br>
                        </li>
                        <li>
                          {" "}
                          • &nbsp; Naselje Slavonija I 4/7 <br></br>
                          35000 Slavonski Brod
                        </li>
                        <li>
                          {" "}
                          • &nbsp; Ulica Hrvatske Republike 33 <br></br>
                          31000 Osijek
                        </li>
                        <li>
                          {" "}
                          • &nbsp; Kralja Zvonimira 69<br></br>
                          32100 Vinkovci
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* End Single Widget  */}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
export default withTranslation()(ContactTwo);
