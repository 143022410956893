import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import { FiHeadphones, FiMail, FiMapPin } from "react-icons/fi";
import GoogleMapReact from "google-map-react";
import { GoogleMap, useLoadScript, Marker } from "react-google-maps/";
import ContactTwo from "./contact/ContactTwo";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import HeaderFive from "../component/header/HeaderFive";
import Footer from "../component/footer/Footer";
import Map from "./Map";

const AnyReactComponent = ({ text }) => <div>{text}</div>;

const defaultProps = {
  center: {
    lat: 45.34,
    lng: 18.57
  },
  zoom: 10
};

const textColor = "#fff";

class Kontakt extends Component {
  render() {
    return (
      <React.Fragment>
        <PageHelmet pageTitle="Kontakt" />

        <HeaderFive
          headerPosition="header--static logoresize"
          logo="all-dark"
          color="color-black"
        />

        {/* Start Contact Page Area  */}
        <div className="rn-contact-page ptb--120 bg_color--3 fingerprintPattern">
          <ContactTwo textColor={textColor} />
        </div>
        {/* End Contact Page Area  */}

        {/* Start Contact Map  */}
        <div className="rn-contact-map-area position-relative">
          <div style={{ height: "650px", width: "100%" }}>
            <Map />
          </div>
        </div>
        {/* End Contact Map  */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default Kontakt;
