import React from "react";
import {
  FaTwitter,
  FaInstagram,
  FaFacebookF,
  FaLinkedinIn
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { withTranslation, useTranslation } from "react-i18next";

const SocialShare = [
  { Social: <FaFacebookF />, link: "https://www.facebook.com/Sintel000" },
  // { Social: <FaLinkedinIn />, link: "https://www.linkedin.com/" },
  {
    Social: <FaInstagram />,
    link: "https://www.instagram.com/revizija_sintel/"
  },
  {
    Social: <FaLinkedinIn />,
    link: "https://www.linkedin.com/company/sintel-d-o-o/?originalSubdomain=hr"
  }
];

const FooterTwo = () => {
  const { t } = useTranslation();

  return (
    <div className="footer-style-2 ptb--30 bg_color--5">
      <div className="wrapper plr--50 plr_sm--20">
        <div className="row align-items-center justify-content-between">
          <div className="col-lg-2 col-md-6 col-sm-6 col-12">
            <div className="inner text-center">
              <ul className="social-share rn-lg-size d-flex justify-content-center liststyle">
                {SocialShare.map((val, i) => (
                  <li key={i}>
                    <a
                      href={`${val.link}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {val.Social}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="col-lg-5 col-md-12 col-sm-12 col-12">
            <div className="inner text-center mt_md--20 mt_sm--20">
              <div className="text">
                <p className="fontMontserrat font10">
                  <ul
                    className=" d-flex justify-content-center liststyle"
                    style={{ lineHeight: "15px" }}
                  >
                    <li className="pr--10">
                      <Link to={`/izjava-o-privatnosti`}>
                        {t("privacy.title")}
                      </Link>
                    </li>
                    <li className="pr--10">
                      <Link to={`/izjava-o-kolacicima`}>
                        {t("cookieStatement.title")}
                      </Link>
                    </li>
                    <li className="pr--10">
                      <Link to={`/pristup-podacima`}>
                        {t("dataAccess.title")}
                      </Link>
                    </li>
                    <li>
                      © {new Date().getFullYear()} {t("allRR.text")}
                    </li>
                  </ul>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default withTranslation()(FooterTwo);
