import React from "react";
import ReactDOM from "react-dom";
import { I18nextProvider, useTranslation } from "react-i18next";
import i18n from "./i18n";
import FacebookChat from "./FacebookChatPlugin";

// Create Import File
import "./index.scss";

import PageScrollTop from "./component/PageScrollTop";

// Home layout
import Pocetna from "./home/Pocetna";

// Element Layout
import Revizija from "./elements/Revizija";
import Racunovodstvo from "./elements/Racunovodstvo";
import ForenzicnoRacunovodstvo from "./elements/ForenzicnoRacunovodstvo";
import ONama from "./elements/ONama";
import Kontakt from "./elements/Kontakt";
import Ponuda from "./elements/Ponuda";
import Blog from "./elements/Blog";
import BlogDetalj from "./elements/BlogDetalj";
import KarijeraOverview from "./elements/KarijeraOverview";
import KarijeraDetails from "./elements/KarijeraDetails";
import error404 from "./elements/error404";
import IzjavaOPrivatnosti from "./elements/IzjavaOPrivatnosti";
import IzjavaOKolacicima from "./elements/IzjavaOKolacicima";
import { LanguageProvider } from "../src/component/header/LanguageContext";

// Blocks Layout

import GoogleMap from "./blocks/GoogleMap";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import Savjetovanje from "./elements/Savjetovanje";
import CookieConsent from "react-cookie-consent";
import PristupPodacima from "./elements/PristupPodacima";

const RedirectToExternalURL = () => {
  window.location.href = "https://lin79.mojsite.com/cpanel";
  return null;
};

const Root = () => {
  const { t } = useTranslation();

  return (
    <BrowserRouter basename={"/"}>
      <PageScrollTop>
        <Switch>
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/`}
            component={Pocetna}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/pocetna`}
            component={Pocetna}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/revizija`}
            component={Revizija}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/racunovodstvene-usluge`}
            component={Racunovodstvo}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/racunovodstvo`}
            component={Racunovodstvo}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/knjigovodstvo`}
            component={Racunovodstvo}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/forenzicno-racunovodstvo`}
            component={ForenzicnoRacunovodstvo}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/savjetovanje`}
            component={Savjetovanje}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/kontakt`}
            component={Kontakt}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/cjenik`}
            component={Kontakt}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/o-nama`}
            component={ONama}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/certifikati`}
            component={ONama}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/blog`}
            component={Blog}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/blog-tekst`}
            component={BlogDetalj}
          />

          {/* Blocks Elements  */}
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/google-map`}
            component={GoogleMap}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/pristup-podacima`}
            component={PristupPodacima}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/izjava-o-kolacicima`}
            component={IzjavaOKolacicima}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/izjava-o-privatnosti`}
            component={IzjavaOPrivatnosti}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/blog-tekst/:slug`}
            component={BlogDetalj}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/karijera`}
            component={KarijeraOverview}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/karijera-objava/:id`}
            component={KarijeraDetails}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/ponuda`}
            component={Ponuda}
          />
          <Route exact path="/cpanel" component={RedirectToExternalURL} />

          <Route path={`${process.env.PUBLIC_URL}/404`} component={error404} />
          <Route component={error404} />
        </Switch>
      </PageScrollTop>
      <FacebookChat />
      <CookieConsent
        location="bottom"
        buttonText={t("cookie.button")}
        cookieName="myAwesomeCookieName2"
        style={{ background: "#1aa6ec" }}
        buttonStyle={{ background: "#fff", fontSize: "13px" }}
        expires={30}
        overlay={true}
        buttonClasses={"fontMontserrat"}
      >
        {" "}
        {t("cookie.text")}
      </CookieConsent>
    </BrowserRouter>
  );
};

ReactDOM.render(
  <LanguageProvider>
    <I18nextProvider i18n={i18n}>
      <Root />
    </I18nextProvider>
  </LanguageProvider>,
  document.getElementById("root")
);
serviceWorker.register();
