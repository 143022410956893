import React, { Fragment, useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { PropagateLoader } from "react-spinners";
import { withTranslation, useTranslation } from "react-i18next";
import { Pagination } from "react-bootstrap";

function BlogList() {
  const [blogPosts, setBlogPosts] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [contentLoaded, setContentLoaded] = useState(false);
  const { t } = useTranslation();
  const { id } = useParams();
  const postsPerPage = 6; // Number of posts to display per page
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const fetchEntries = async () => {
      setIsLoading(true);

      try {
        const delay = 800; // 2 seconds
        setTimeout(() => {
          setIsLoading(false);
        }, delay);

        const response = await fetch("/.netlify/functions/fetchBlogPosts");

        if (!response.ok) {
          throw new Error("Failed to fetch blog posts");
        }

        const data = await response.json();
        const blogPosts = data.items;
        setPhotos(data.includes.Asset);

        if (blogPosts) {
          setBlogPosts(blogPosts);
        } else {
          console.log("Blog post not found");
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchEntries();
  }, []);

  // Pagination
  const totalPages = Math.ceil(blogPosts.length / postsPerPage); // Calculate the total number of pages

  const paginate = pageNumber => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = blogPosts
    .filter(post => post.sys.contentType.sys.id !== "karijera")
    .sort((a, b) => new Date(b.fields.createdAt) - new Date(a.fields.createdAt))
    .slice(indexOfFirstPost, indexOfLastPost);

  return (
    <Fragment>
      {isLoading && (
        <div className="spinner-container">
          <PropagateLoader color="#0085c0" size={20} />
        </div>
      )}
      {!isLoading && (
        <div className="row">
          {currentPosts.map((blog, i) => {
            if (blog.sys.contentType.sys.id !== "karijera") {
              const photo = photos.find(
                item => item.sys.id === blog.fields.blogPhoto.sys.id
              );

              if (!photo) {
                return null; // Skip rendering if the associated photo is not found
              }

              const { title, file } = photo.fields;
              return (
                <div
                  className="col-lg-6 col-md-6 col-sm-6 col-12"
                  key={blog.sys.id}
                >
                  <div className="blog blog-style--1">
                    <div className="thumbnail">
                      <Link to={`/blog-tekst/${blog.fields.slug}`}>
                        <img className="w-100" src={file.url} alt={title} />
                      </Link>
                    </div>
                    <div className="content">
                      <h4 className="title pb--15">
                        <Link to={`/blog-tekst/${blog.fields.slug}`}>
                          {blog.fields.title}
                        </Link>
                      </h4>
                      <div className="rn-button-style--3 btn-primary-color fontMontserrat">
                        <Link
                          to={`/blog-tekst/${blog.fields.slug}`}
                          className="rn-btnWhite"
                        >
                          {t("blog.readMore")}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
          })}
        </div>
      )}

      {/* Pagination */}
      {blogPosts.length > 6 && (
        <div className="d-flex justify-content-center mt-4">
          <Pagination size="md">
            {Array.from({ length: totalPages }).map((_, index) => (
              <Pagination.Item
                key={index}
                active={currentPage === index + 1}
                onClick={() => paginate(index + 1)}
              >
                {index + 1}
              </Pagination.Item>
            ))}
          </Pagination>
        </div>
      )}
    </Fragment>
  );
}

export default withTranslation()(BlogList);
