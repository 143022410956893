import React, { useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaCopy } from "react-icons/fa";
import { withTranslation, useTranslation } from "react-i18next";

function MyComponent() {
  const [isCopied, setIsCopied] = useState(false);
  const { t } = useTranslation(); // Move useTranslation hook inside the component

  const handleCopy = () => {
    const link = window.location.href;

    // Copy the link to the clipboard
    navigator.clipboard
      .writeText(link)
      .then(() => {
        // Set the state to indicate the link has been copied
        setIsCopied(true);

        // Reset the state after a certain duration
        setTimeout(() => {
          setIsCopied(false);
        }, 2000); // Reset after 2 seconds
      })
      .catch(error => {
        console.log("Failed to copy link:", error);
      });
  };

  return (
    <div>
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip
            id="copy-tooltip"
            className={`my-tooltip ${isCopied ? "show" : ""}`}
          >
            {t("blog.linkCopied")}
          </Tooltip>
        }
        trigger="click"
        rootClose
      >
        <button
          onClick={handleCopy}
          className="blog-button-style--2 btn-solid"
          style={{ background: "#fff", color: "slategrey" }}
        >
          <FaCopy /> {t("blog.share")}
        </button>
      </OverlayTrigger>
    </div>
  );
}

export default withTranslation()(MyComponent);
