import React, { useState, useRef } from "react";
import swal from "sweetalert2";
import ReCAPTCHA from "react-google-recaptcha";

const OtvorenaZamolba = ({ jobTitle }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [attachment, setAttachment] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const fileInputRef = useRef(null);
  const [isVerified, setIsVerified] = useState(false);
  const recaptchaRef = useRef();

  const handleAttachmentChange = e => {
    setAttachment(e.target.files[0]);
  };

  const handleRecaptchaChange = () => {
    setIsVerified(true);
  };

  const sendEmail = async formData => {
    const apiKey = process.env.REACT_APP_ELASTIC_EMAIL_API_KEY;

    try {
      const form = new FormData();
      form.append("name", formData.name);
      form.append("email", formData.email);
      form.append("phone", formData.phone);
      form.append("message", formData.message);
      form.append("attachment", formData.attachment);
      form.append(
        "subject",
        `${jobTitle ? "Prijava za posao: " + jobTitle : "Otvorena molba"}`
      );
      form.append("to", "sintel@sintel.hr");
      form.append("from", "sintel@sintel.hr");
      form.append(
        "bodyHtml",
        `
        <div style="font-family: Montserrat, sans-serif; color: #1aa6ec;">
          <h2 style="color: #FF0000;">${
            jobTitle ? "Prijava za posao: " + jobTitle : "Otvorena molba"
          }</h2>
          <p><strong style=color: #1aa6ec;">Ime:</strong> ${formData.name}</p>
          <p><strong style=color: #1aa6ec;">Email:</strong> ${
            formData.email
          }</p>
          <p><strong style=color: #1aa6ec;">Telefon:</strong> ${
            formData.phone
          }</p>
          <p><strong style=color: #1aa6ec;">Motivacija:</strong> ${
            formData.message
          }</p>
        </div>
      `
      );
      form.append("file", formData.attachment);

      const response = await fetch(
        "https://api.elasticemail.com/v2/email/send",
        {
          method: "POST",
          body: form,
          headers: {
            "X-ElasticEmail-ApiKey": apiKey
          }
        }
      );

      if (response.ok) {
        console.log("Email sent successfully");
        // Optionally, perform any success actions here
      } else {
        throw new Error("Email sending failed");
      }
    } catch (error) {
      console.error("Error sending email:", error);
      // Optionally, handle the error and perform any error actions here
    }
  };
  const handleSubmit = async event => {
    event.preventDefault(); // Prevent the default form submission behavior
    if (!isVerified) {
      // reCAPTCHA verification failed
      return;
    }
    setIsSubmitting(true);
    const formData = { name, email, phone, message, attachment }; // Collect form data
    // Call the sendEmail function
    await sendEmail(formData);
    // Clear the form fields after successful submission
    setName("");
    setEmail("");
    setPhone("");
    setMessage("");
    setAttachment(null);
    // Display success message
    swal.fire({
      title: "Zahvaljujemo!",
      text: "Prijava je uspješno poslana!",
      icon: "success",
      timer: 4000,
      timerProgressBar: true,
      confirmButtonColor: "#0085c0"
    });
    setIsSubmitting(false);
    fileInputRef.current.value = "";
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="rn-form-group">
        <input
          className="fontMontserrat"
          type="text"
          value={name}
          onChange={e => setName(e.target.value)}
          placeholder="Vaše ime i prezime"
          required
          style={{ backgroundColor: "#fff", borderColor: "#1a9adb" }}
        />
      </div>
      <div className="rn-form-group">
        <input
          value={email}
          onChange={e => setEmail(e.target.value)}
          className="fontMontserrat"
          type="email"
          name="email"
          placeholder="Vaš email"
          required
          style={{ backgroundColor: "#fff", borderColor: "#1a9adb" }}
        />
      </div>
      <div className="rn-form-group">
        <input
          value={phone}
          onChange={e => setPhone(e.target.value)}
          className="fontMontserrat"
          type="text"
          name="phone"
          placeholder="Vaš telefonski broj"
          required
          style={{ backgroundColor: "#fff", borderColor: "#1a9adb" }}
        />
      </div>
      <div className="rn-form-group">
        <textarea
          value={message}
          onChange={e => setMessage(e.target.value)}
          className="fontMontserrat"
          name="message"
          placeholder="Motivacijski tekst"
          required
          style={{ backgroundColor: "#fff", borderColor: "#1a9adb" }}
        ></textarea>
      </div>
      <input
        type="file"
        ref={fileInputRef}
        required
        onChange={handleAttachmentChange}
        style={{ border: "none", lineHeight: "35px", paddingLeft: "0px" }}
      />

      {/* <input type="file" onChange={handleAttachmentChange} /> */}
      <ReCAPTCHA
        ref={recaptchaRef}
        sitekey="6Ldev18mAAAAAEm7W12m0gdnVhOOi-6-laQYezI2"
        onChange={handleRecaptchaChange}
        className="pb--20"
      />
      <div className="rn-form-group">
        <button
          className={
            !email ||
            !name ||
            !email ||
            !message ||
            !attachment ||
            !isVerified ||
            isSubmitting
              ? "disabledButton btn-solidDisabled fontMontserrat"
              : "rn-button-style--2 btn-solid"
          }
          type="submit"
          value="submit"
          name="submit"
          id="mc-embedded-subscribe"
          disabled={
            !name ||
            !email ||
            !phone ||
            !message ||
            !attachment ||
            isSubmitting ||
            !isVerified
          }
        >
          {isSubmitting ? "Slanje" : "Pošalji"}
        </button>
      </div>
    </form>
  );
};
export default OtvorenaZamolba;
