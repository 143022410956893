import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import HeaderFive from "../component/header/HeaderFive";
import Footer from "../component/footer/Footer";
import { PropagateLoader } from "react-spinners";
import OtvorenaContact from "./contact/OtvorenaContact";
import ShareButton from "../elements/ShareButton";

function KarijeraDetails() {
  const [singleBlogPost, setSingleBlogPost] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [contentLoaded, setContentLoaded] = useState(false);
  const access_token = process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN;

  const { id } = useParams();
  const history = useHistory();

  const handleGoBack = () => {
    history.goBack();
  };

  useEffect(() => {
    const fetchEntries = async () => {
      setIsLoading(true);

      try {
        const delay = 1000; // 2 seconds

        setTimeout(() => {
          setIsLoading(false);
        }, delay);

        const response = await fetch("/.netlify/functions/fetchBlogPosts");

        if (!response.ok) {
          throw new Error("Failed to fetch career posts");
        }
        const data = await response.json();
        const blogPost = data.items.find(item => item.sys.id === id);

        if (blogPost) {
          setSingleBlogPost(blogPost);
          setIsLoading(false);
        } else {
          console.log("Career post not found");
        }

        setPhotos(data.includes.Asset);
        // return () => clearTimeout(timer);
      } catch (error) {
        console.log(error);
      }
    };

    fetchEntries();
  }, []);

  const formatDate = dateString => {
    const date = new Date(dateString);
    const options = {
      day: "numeric",
      month: "long",
      year: "numeric"
    };
    return date.toLocaleDateString("hr-HR", options);
  };

  return (
    <React.Fragment>
      {isLoading && (
        <div className="spinner-container">
          {" "}
          {<PropagateLoader color="#0085c0" size={20} />}
        </div>
      )}
      <PageHelmet pageTitle="Karijera" />
      <HeaderFive
        headerPosition="header--static logoresize"
        logo="all-dark"
        color="color-black"
      />
      {/* Start Breadcrump Area */}
      {!isLoading && (
        <React.Fragment>
          <PageHelmet pageTitle={singleBlogPost.fields.naslovOglasa} />
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="blog-single-page-title text-center pt--100">
                  <h2 className="fontMontserrat">
                    {singleBlogPost.fields ? (
                      singleBlogPost.fields.naslovOglasa
                    ) : (
                      <PropagateLoader />
                    )}
                  </h2>
                  <ul className="blog-meta d-flex justify-content-center align-items-center">
                    <li>
                      Datum objave:
                      <br />
                      {formatDate(
                        singleBlogPost.fields ? (
                          singleBlogPost.sys.createdAt
                        ) : (
                          <PropagateLoader />
                        )
                      )}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="rn-blog-details pt--50 pb--70 bg_color--1">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="inner-wrapper">
                    <div className="inner fontMontserrat justifyText">
                      <ReactMarkdown>
                        {singleBlogPost.fields ? (
                          singleBlogPost.fields.opisOglasa
                        ) : (
                          <PropagateLoader />
                        )}
                      </ReactMarkdown>
                      <ShareButton />
                      <button
                        className="blog-button-style--2 btn-solid "
                        onClick={handleGoBack}
                      >
                        Natrag
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="rn-contact-page ptb--120 bg_color--1">
            <OtvorenaContact jobTitle={singleBlogPost.fields.naslovOglasa} />
          </div>
          <div className="backto-top">
            <ScrollToTop showUnder={160}>
              <FiChevronUp />
            </ScrollToTop>
          </div>
          {/* End Back To Top */}

          <Footer />
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
export default KarijeraDetails;
