import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Footer from "../component/footer/Footer";
import HeaderFive from "../component/header/HeaderFive";
import { withTranslation } from "react-i18next";

class Revizija extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false
    };
  }

  renderDescription(text) {
    return (
      <p
        className="fontMontserrat justifyText"
        dangerouslySetInnerHTML={{ __html: this.props.t(text) }}
      ></p>
    );
  }

  render() {
    const { t } = this.props;

    return (
      <React.Fragment>
        {/* Start Pagehelmet  */}
        <PageHelmet pageTitle="Revizija" />
        {/* End Pagehelmet  */}

        <HeaderFive
          headerPosition="header--static logoresize"
          logo="all-dark"
          color="color-black"
        />

        {/* Start Breadcrump Area */}
        {/*         <div
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--34"
          data-black-overlay="5"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title theme-gradient">REVIZIJA</h2>
                  {/*                   <p>Fresh From The Press Discription</p>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* End Breadcrump Area */}

        {/* Start Page Wrapper */}
        <div className="rn-service-details ptb--120 bg_color--5 fingerprintPattern">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="service-details-inner">
                  <div className="inner">
                    {/* Start Single Area */}
                    <div className="row sercice-details-content pb--80 align-items-center">
                      <div className="col-lg-6 col-12">
                        <div className="thumb pt--20">
                          <img
                            className="w-100"
                            src="/assets/images/service/revizija1.jpeg"
                            alt="Revizija"
                            title="Revizija - Sintel Revizija"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="details mt_md--30 mt_sm--30">
                          <h4 className="title fontMontserrat">
                            {t("Revizija.pageTitle")}
                          </h4>
                          <p
                            className="fontMontserrat justifyText"
                            dangerouslySetInnerHTML={{
                              __html: t("Revizija.description1")
                            }}
                          ></p>

                          <p className="fontMontserrat">
                            {this.renderDescription("Revizija.description2")}
                          </p>
                          <p className="fontMontserrat">
                            <b>
                              {" "}
                              {this.renderDescription("Revizija.description3")}
                            </b>
                          </p>
                          <br></br>
                          <ul className="liststyle fontMontserrat">
                            <li>
                              {this.props.t("Revizija.description3ListItem1")}
                            </li>
                            <li>
                              {this.props.t("Revizija.description3ListItem2")}
                            </li>
                            <li>
                              {this.props.t("Revizija.description3ListItem3")}
                            </li>
                            <li>
                              {this.props.t("Revizija.description3ListItem4")}
                            </li>
                            <li>
                              {this.props.t("Revizija.description3ListItem5")}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}

                    {/* Start Single Area */}
                    <div className="row sercice-details-content align-items-center">
                      <div className="col-lg-6 col-12 order-2 order-lg-1">
                        <div className="details mt_md--30 mt_sm--30">
                          <p className="fontMontserrat justifyText">
                            {this.renderDescription("Revizija.description4")}
                          </p>
                          <p className="fontMontserrat">
                            {this.renderDescription("Revizija.description5")}
                          </p>
                          <p className="fontMontserrat">
                            <b> {this.props.t("Revizija.description6")}</b>
                          </p>
                          <br></br>
                          <ul className="liststyle fontMontserrat">
                            <li>{this.props.t("Revizija.description7")}</li>
                            <li>{this.props.t("Revizija.description8")}</li>
                            <li>{this.props.t("Revizija.description9")}</li>
                            <li>{this.props.t("Revizija.description10")}</li>
                            <li>{this.props.t("Revizija.description11")}</li>
                            <li>{this.props.t("Revizija.description12")} </li>
                            <li>{this.props.t("Revizija.description13")} </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-lg-6 col-12 order-1 order-lg-2">
                        <div className="thumb position-relative">
                          <img
                            className="w-100"
                            src="/assets/images/service/revizija2.jpeg"
                            alt="Revizija"
                            title="Revizija - Sintel Revizija"
                          />
                          {/* <ModalVideo
                            channel="youtube"
                            isOpen={this.state.isOpen}
                            videoId="ZOoVOfieAF8"
                            onClose={() => this.setState({ isOpen: false })}
                          />
                          <button
                            className="video-popup"
                            onClick={this.openModal}
                          >
                            <span className="play-icon"></span>
                          </button>
                          */}
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Page Wrapper */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default withTranslation()(Revizija);
