import React, { useState, useRef } from "react";
import swal from "sweetalert2";
import ReCAPTCHA from "react-google-recaptcha";
import { withTranslation, useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { blue } from "@mui/material/colors";
import { useTheme } from "@mui/system";

const PonudaForm = () => {
  const elasticEmailAPIkey = process.env.REACT_APP_ELASTIC_EMAIL_API_KEY;
  const [name, setName] = useState("");
  const [pravniOblik, setPravniOblik] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [isValidPhone, setIsValidPhone] = useState(false);
  const [brojZaposlenih, setBrojZaposlenih] = useState("");
  const [pdv, setPdv] = useState(false);
  const [vrstaDjelatnosti, setVrstaDjelatnosti] = useState("");
  const [brojUlaznihRacuna, setBrojUlaznihRacuna] = useState("");
  const [customPravniOblik, setCustomPravniOblik] = useState("");
  const [customVrstaDjelatnosti, setCustomVrstaDjelatnosti] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [GDPRchecked, setGDPRChecked] = React.useState(false);
  const recaptchaRef = useRef();
  const { t } = useTranslation();
  const theme = useTheme();

  const handlePhoneChange = e => {
    const value = e.target.value;

    // Validate phone number
    const phoneRegex = /^\d{6,15}$/; // Allow numbers only, min 6 digits, max 15 digits
    const isValidPhone = phoneRegex.test(value);
    console.log(isValidPhone);

    // Update the state based on validation
    setPhone(value);
    setIsValidPhone(isValidPhone);
  };

  const handleGDPRChange = event => {
    setGDPRChecked(event.target.checked);
  };

  const handleRecaptchaChange = () => {
    setIsVerified(true);
  };

  const handlePravniOblikChange = event => {
    const selectedValue = event.target.value;
    setPravniOblik(selectedValue);

    setCustomPravniOblik("");
  };

  const handleCustomPravniOblik = event => {
    const selectedValue = event.target.value;
    setCustomPravniOblik(selectedValue);
  };

  const handlePDVChange = event => {
    const selectedValue = event.target.value;
    setPdv(selectedValue);
  };

  const handleVrstaDjelatnostiChange = event => {
    const selectedValue = event.target.value;
    setVrstaDjelatnosti(selectedValue);

    setCustomVrstaDjelatnosti("");
  };

  const handleCustomVrstaDjelatnosti = event => {
    const selectedValue = event.target.value;
    setCustomVrstaDjelatnosti(selectedValue);
  };

  const handleBrojUlazanihRacunaChange = event => {
    const selectedValue = event.target.value;
    setBrojUlaznihRacuna(selectedValue);
  };

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      // Last step, submit the form
      handleSubmit();
    } else {
      // Not the last step, proceed to the next step
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const privola = GDPRchecked
    ? "Korisnik je dao pristanak za korištenje svojih podataka u svrhu kreiranja ponude."
    : "Korisnik NIJE dao pristanak za korištenje svojih podataka u svrhu kreiranja ponude.";

  const sendEmail = async formData => {
    try {
      const form = new FormData();
      form.append("apikey", elasticEmailAPIkey);
      form.append("subject", `${"Zahtjev za ponudu"}`);
      form.append("to", "sintel@sintel.hr");
      form.append("from", "sintel@sintel.hr");
      form.append(
        "bodyHtml",
        `
        <div style="font-family: Montserrat, sans-serif; color: #1aa6ec;">
          <h2 style="color: #FF0000;">${"Zahtjev za ponudu putem web stranice sintel.hr:"}</h2>
          <p><strong style=color: #1aa6ec;">Ime/Firma:</strong> ${
            formData.name
          }</p>
          <p><strong style=color: #1aa6ec;">Pravni oblik:</strong> ${
            formData.pravniOblik
          }</p>
          <p><strong style=color: #1aa6ec;">Email:</strong> ${
            formData.email
          }</p>
          <p><strong style=color: #1aa6ec;">Telefon:</strong> ${
            formData.phone
          }</p>
          <p><strong style=color: #1aa6ec;">Broj zaposlenih:</strong> ${
            formData.brojZaposlenih
          }</p>
          <p><strong style=color: #1aa6ec;">Sustav PDV-a:</strong> ${
            formData.pdv
          }</p>
          <p><strong style=color: #1aa6ec;">Vrsta djelatnosti:</strong> ${
            formData.vrstaDjelatnosti
          }</p>
          <p><strong style=color: #1aa6ec;">Broj ulaznih računa:</strong> ${
            formData.brojUlaznihRacuna
          }</p>
          <p><strong style=color: #1aa6ec;">${privola}</strong> </p>
        </div>
      `
      );

      const response = await fetch(
        "https://api.elasticemail.com/v2/email/send",
        {
          method: "POST",
          body: form
        }
      );

      if (response.ok) {
        console.log("Email sent successfully");
        // Optionally, perform any success actions here
      } else {
        throw new Error("Email sending failed");
      }
    } catch (error) {
      console.error("Error sending email:", error);
      // Optionally, handle the error and perform any error actions here
    }
  };

  const handleSubmit = async event => {
    if (event) {
      event.preventDefault(); // Prevent the default form submission behavior
    }
    // if (!isVerified) {
    //   // reCAPTCHA verification failed
    //   return;
    // }
    setIsSubmitting(true);

    const formData = {
      name,
      pravniOblik: pravniOblik === "Ostalo" ? customPravniOblik : pravniOblik,
      email,
      phone,
      brojZaposlenih,
      pdv,
      vrstaDjelatnosti:
        vrstaDjelatnosti === "Ostalo"
          ? customVrstaDjelatnosti
          : vrstaDjelatnosti,
      brojUlaznihRacuna
    }; // Collect form data

    // Call the sendEmail function
    await sendEmail(formData);

    // Clear the form fields after successful submission
    setName("");
    setPravniOblik("");
    setEmail("");
    setPhone("");
    setBrojZaposlenih(null);
    setPdv(false);
    setVrstaDjelatnosti("");
    setBrojUlaznihRacuna("");
    setGDPRChecked(false);
    // Display success message
    swal.fire({
      title: t("contactForm.thankyou"),
      text: t("contactForm.messageSent"),
      icon: "success",
      timer: 4000,
      timerProgressBar: true,
      confirmButtonColor: "#0085c0"
    });
    setIsSubmitting(false);
  };

  const steps = [
    {
      label: t("ponuda.korak1"),
      forms: [
        <div className="rn-form-group">
          <input
            className="fontMontserrat"
            type="text"
            value={name}
            onChange={e => setName(e.target.value)}
            placeholder={t("contactForm.name")}
            required
            style={{ backgroundColor: "#fff", borderColor: "#1a9adb" }}
          />
        </div>,
        <div className="rn-form-group">
          <input
            value={email}
            onChange={e => setEmail(e.target.value)}
            className="fontMontserrat"
            type="email"
            name="email"
            placeholder={t("contactForm.email")}
            required
            style={{ backgroundColor: "#fff", borderColor: "#1a9adb" }}
          />
        </div>,
        <div className="rn-form-group">
          <input
            value={phone}
            onChange={handlePhoneChange}
            className="fontMontserrat"
            type="text"
            name="phone"
            placeholder={t("contactForm.phone")}
            required
            style={{
              backgroundColor: "#fff"
            }}
          />
        </div>
      ]
    },
    {
      label: t("ponuda.korak2"),
      forms: [
        <div className="rn-form-group">
          <label>
            <select
              value={pravniOblik}
              onChange={handlePravniOblikChange}
              style={{ backgroundColor: "#fff", borderColor: "#1a9adb" }}
              required
            >
              <option value="">{t("ponuda.oblik")}</option>
              <option value="d.o.o/j.d.o">{t("ponuda.doojdo")}</option>
              <option value="Obrt">{t("ponuda.obrt")}</option>
              <option value="Neprofitna organizacija/Udruga">
                {t("ponuda.udruga")}
              </option>
              <option value="Ostalo">{t("ponuda.ostalo")}</option>
            </select>
          </label>

          {pravniOblik === "Ostalo" && (
            <div>
              <label className={"fontMontserrat"} style={{ color: "white" }}>
                {t("ponuda.opis")}
                <input
                  type="text"
                  value={customPravniOblik}
                  onChange={handleCustomPravniOblik}
                  style={{ backgroundColor: "#fff", borderColor: "#1a9adb" }}
                  required
                />
              </label>
            </div>
          )}
        </div>,
        <div className="rn-form-group">
          <input
            value={brojZaposlenih}
            onChange={e => {
              const inputValue = e.target.value;
              // Check if the input is a positive number or an empty string
              if (/^\d+$/.test(inputValue) || inputValue === "") {
                setBrojZaposlenih(inputValue);
              }
            }}
            className="fontMontserrat"
            type="number"
            name="brojZaposlenih"
            placeholder={t("ponuda.zaposleni")}
            required
            style={{ backgroundColor: "#fff", borderColor: "#1a9adb" }}
          />
        </div>,
        <div className="rn-form-group">
          <label>
            <select
              value={pdv}
              onChange={handlePDVChange}
              style={{ backgroundColor: "#fff", borderColor: "#1a9adb" }}
              required
            >
              <option value="">{t("ponuda.pdv")}</option>
              <option value="Da">{t("ponuda.da")}</option>
              <option value="Ne">{t("ponuda.ne")}</option>
            </select>
          </label>
        </div>
      ]
    },
    {
      label: t("ponuda.korak3"),
      forms: [
        <div className="rn-form-group">
          <label>
            <select
              value={vrstaDjelatnosti}
              onChange={handleVrstaDjelatnostiChange}
              style={{ backgroundColor: "#fff", borderColor: "#1a9adb" }}
              required
            >
              <option value="">{t("ponuda.vrsta")}</option>
              <option value="Usluga">{t("ponuda.usluga")}</option>
              <option value="Proizvodnja">{t("ponuda.proizvodnja")}</option>
              <option value="Ugostiteljstvo">
                {t("ponuda.ugostiteljstvo")}
              </option>
              <option value="Maloprodaja">{t("ponuda.maloprodaja")}</option>
              <option value="Građevinarstvo">
                {t("ponuda.gradevinarstvo")}
              </option>
              <option value="Ostalo">{t("ponuda.ostalo")}</option>
            </select>
          </label>

          {vrstaDjelatnosti === "Ostalo" && (
            <div>
              <label className={"fontMontserrat"} style={{ color: "white" }}>
                {t("ponuda.opisDjelatnosti")}
                <input
                  type="text"
                  value={customVrstaDjelatnosti}
                  onChange={handleCustomVrstaDjelatnosti}
                  style={{ backgroundColor: "#fff", borderColor: "#1a9adb" }}
                  required
                />
              </label>
            </div>
          )}
        </div>,
        <div className="rn-form-group">
          <label>
            <select
              value={brojUlaznihRacuna}
              onChange={handleBrojUlazanihRacunaChange}
              style={{ backgroundColor: "#fff", borderColor: "#1a9adb" }}
              required
            >
              <option value="">{t("ponuda.brojRacuna")}</option>
              <option value="do 20">{t("ponuda.do20")}</option>
              <option value="do 50">{t("ponuda.do50")}</option>
              <option value="do 100">{t("ponuda.do100")}</option>
              <option value="preko 100">{t("ponuda.preko100")}</option>
            </select>
          </label>
        </div>
      ]
    }
  ];

  const submitButton = (
    <div className="rn-form-group">
      <div
        className={
          !email || !name || !isVerified || isSubmitting
            ? " fontMontserrat"
            : "rn-button-style--2 btn-solid"
        }
        type="submit"
        value="submit"
        name="submit"
        id="mc-embedded-subscribe"
        disabled={
          !name ||
          !email ||
          !phone ||
          !isVerified ||
          !brojZaposlenih ||
          !pdv ||
          !vrstaDjelatnosti ||
          !brojUlaznihRacuna ||
          !GDPRchecked ||
          isSubmitting
        }
      >
        {isSubmitting ? t("contactForm.sending") : t("contactForm.send")}
      </div>
    </div>
  );

  const isValidEmail = email => {
    // Use a regular expression for basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validationFunctions = [
    () => !name || !email || !phone || !isValidEmail(email),
    () => {
      if (pravniOblik === "Ostalo") {
        return !customPravniOblik || !brojZaposlenih || !pdv;
      }
      // Handle the case when pravniOblik is not "ostalo"
      return !pravniOblik || !brojZaposlenih || !pdv;
    },
    () =>
      !vrstaDjelatnosti ||
      (vrstaDjelatnosti === "Ostalo" && !customVrstaDjelatnosti) ||
      !brojUlaznihRacuna,
    () => !isVerified,
    () => !isValidPhone
  ];

  const isStepDisabled = validationFunctions[activeStep]();

  const reCaptcha = (
    <ReCAPTCHA
      ref={recaptchaRef}
      sitekey="6Ldev18mAAAAAEm7W12m0gdnVhOOi-6-laQYezI2"
      onChange={handleRecaptchaChange}
      className="pb--20"
    />
  );

  return (
    <form onSubmit={handleSubmit}>
      <Box sx={{ maxWidth: 400 }}>
        <Stepper
          activeStep={activeStep}
          orientation="vertical"
          sx={{
            [theme.breakpoints.down("sm")]: {
              width: "100%" // Set the width for smaller screens
            },
            [theme.breakpoints.up("md")]: {
              width: "600px" // Set the width for medium and larger screens
            }
          }}
        >
          {steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel>
                <span className={"fontMontserrat"} style={{ color: "white" }}>
                  {step.label}
                </span>
              </StepLabel>
              <StepContent>
                <Box>
                  {step.forms.map((form, formIndex) => (
                    <div key={formIndex}>{form}</div>
                  ))}
                </Box>
                <Box sx={{ mt: 2 }}>
                  {index === steps.length - 1 ? reCaptcha : null}
                  {index === steps.length - 1 ? (
                    <span
                      className={"fontMontserrat"}
                      style={{ color: "white", fontSize: "12px" }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={GDPRchecked}
                            onChange={handleGDPRChange}
                            inputProps={{ "aria-label": "controlled" }}
                            sx={{
                              color: blue[800],
                              "&.Mui-checked": {
                                color: blue[600]
                              }
                            }}
                          />
                        }
                        label={t("ponuda.gdpr")}
                        sx={{
                          ".MuiTypography-root.MuiFormControlLabel-label": {
                            color: blue[50],
                            fontSize: "small"
                          }
                        }}
                      />
                    </span>
                  ) : null}
                  <div>
                    <Button
                      variant="contained"
                      onClick={handleNext}
                      sx={{ mt: 1, mr: 1 }}
                      disabled={
                        isStepDisabled ||
                        isSubmitting ||
                        (index === steps.length - 1 &&
                          (!isVerified || !GDPRchecked)) ||
                        !isValidPhone
                      }
                    >
                      {index === steps.length - 1
                        ? submitButton
                        : t("ponuda.dalje")}
                    </Button>
                    <Button
                      disabled={index === 0}
                      onClick={handleBack}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      {t("ponuda.natrag")}
                    </Button>
                  </div>
                </Box>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Box>
    </form>
  );
};

export default withTranslation()(PonudaForm);
