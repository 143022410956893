import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from "react-modal-video";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Footer from "../component/footer/Footer";
import HeaderFive from "../component/header/HeaderFive";
import { withTranslation } from "react-i18next";

class ForenzicnoRacunovodstvo extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  renderDescription(text) {
    return (
      <p
        className="fontMontserrat justifyText"
        dangerouslySetInnerHTML={{ __html: this.props.t(text) }}
      ></p>
    );
  }
  render() {
    return (
      <React.Fragment>
        {/* Start Pagehelmet  */}
        <PageHelmet pageTitle="Forenzično Savjetovanje" />
        {/* End Pagehelmet  */}

        <HeaderFive
          headerPosition="header--static logoresize"
          logo="all-dark"
          color="color-black"
        />
        {/* Start Page Wrapper */}
        <div className="rn-service-details ptb--120 bg_color--5 fingerprintPattern">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="service-details-inner">
                  <div className="inner">
                    {/* Start Single Area */}
                    <div className="row sercice-details-content pb--80 align-items-center">
                      <div className="col-lg-6 col-12">
                        <div className="thumb pt--20">
                          <img
                            className="w-100"
                            src="/assets/images/service/foren2.png"
                            alt="Knjigovodstvo - Revizija Sintel"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="details mt_md--30 mt_sm--30 justifyText">
                          <h4 className="title">
                            {" "}
                            {this.props.t("forensicAccounting.title")}
                          </h4>
                          <p>
                            {this.renderDescription(
                              "forensicAccounting.servicesIntro"
                            )}{" "}
                            <br></br>
                          </p>
                          <p>
                            {" "}
                            {this.renderDescription(
                              "forensicAccounting.forensicAccountingDesc"
                            )}
                          </p>
                          <p>
                            <b>
                              {this.props.t(
                                "forensicAccounting.commonFraudAreas"
                              )}
                            </b>
                          </p>
                          <ul className="liststyle">
                            <li>
                              {" "}
                              {this.props.t(
                                "forensicAccounting.fraudAreasList1"
                              )}
                            </li>
                            <li>
                              {this.props.t(
                                "forensicAccounting.fraudAreasList2"
                              )}
                            </li>
                            <li>
                              {" "}
                              {this.props.t(
                                "forensicAccounting.fraudAreasList3"
                              )}
                            </li>
                            <li>
                              {" "}
                              {this.props.t(
                                "forensicAccounting.fraudAreasList4"
                              )}
                            </li>
                            <li>
                              {this.props.t(
                                "forensicAccounting.fraudAreasList5"
                              )}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}

                    {/* Start Single Area */}
                    <div className="row sercice-details-content align-items-center">
                      <div className="col-lg-6 col-12 order-2 order-lg-1">
                        <div className="details mt_md--30 mt_sm--30 justifyText">
                          <p>
                            {this.props.t(
                              "forensicAccounting.documentEvidence"
                            )}
                          </p>
                          <p></p>
                          <p>{this.props.t("forensicAccounting.purpose")}</p>
                          <p></p>
                          <b>
                            {this.props.t("forensicAccounting.reasonsToEngage")}
                          </b>
                          <ul className="liststyle">
                            <li>
                              {this.props.t("forensicAccounting.reasonsList1")}{" "}
                            </li>
                            <li>
                              {" "}
                              {this.props.t(
                                "forensicAccounting.reasonsList2"
                              )}{" "}
                            </li>
                            <li>
                              {" "}
                              {this.props.t(
                                "forensicAccounting.reasonsList3"
                              )}{" "}
                            </li>
                            <li>
                              {this.props.t("forensicAccounting.reasonsList4")}{" "}
                            </li>
                            <li>
                              {" "}
                              {this.props.t(
                                "forensicAccounting.reasonsList5"
                              )}{" "}
                            </li>
                            <li>
                              {" "}
                              {this.props.t(
                                "forensicAccounting.reasonsList6"
                              )}{" "}
                            </li>
                            <li>
                              {" "}
                              {this.props.t(
                                "forensicAccounting.reasonsList7"
                              )}{" "}
                            </li>
                            <li>
                              {this.props.t("forensicAccounting.reasonsList8")}
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-lg-6 col-12 order-1 order-lg-2">
                        <div className="thumb position-relative">
                          <img
                            className="w-100"
                            src="/assets/images/service/foren1.png"
                            alt="Forenzično računovodstvo - Revizija Sintel"
                          />
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Page Wrapper */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default withTranslation()(ForenzicnoRacunovodstvo);
