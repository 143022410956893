import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ContactTwo from "./contact/ContactTwo";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import HeaderFive from "../component/header/HeaderFive";
import Footer from "../component/footer/Footer";
import PonudaWrapper from "./contact/PonudaWrapper";

const textColor = "#fff";

class Ponuda extends Component {
  render() {
    return (
      <React.Fragment>
        <PageHelmet pageTitle="Ponuda knjigovodstvo" />

        <HeaderFive
          headerPosition="header--static logoresize"
          logo="all-dark"
          color="color-black"
        />

        {/* Start Contact Page Area  */}
        <div className="rn-contact-page ptb--120 bg_color--3 fingerprintPattern">
          <PonudaWrapper textColor={textColor} />
        </div>
        {/* End Contact Page Area  */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default Ponuda;
